import { APIService } from '@services/api/common-services';
import { APICONFIG } from '@config/api.config';
import { RecommendedProductData } from '@components/contractor360/components/contractorSites';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';

interface ProductWeight {
	membershipNo: string;
}

interface IRecommendedProductsResponse {
	mutate: UseMutateFunction<RecommendedProductData[], Error, ProductWeight, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useProductRecommendationQuery = (): IRecommendedProductsResponse => {
	const fetchRecommendedProducts = async (params: ProductWeight): Promise<RecommendedProductData[]> => {
		return APIService.getData<RecommendedProductData[]>(
			`${APICONFIG.GET_PRODUCT_RECOMMENDATION_LIST}?membership_no=${params.membershipNo}`
		);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: fetchRecommendedProducts,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
