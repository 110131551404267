import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';

export interface DetailsOfSubContractor {
	membershipno: string | number | null | undefined;
	name: string | null;
}
export interface SubcontractorsDetails {
	count: number;
	data: DetailsOfSubContractor[];
}

export const useGetContractorDetails = (
	memberShipNo: string | null | number | undefined
): IQueryResponse<SubcontractorsDetails> => {
	const fetchContractorDetail = async (): Promise<SubcontractorsDetails> => {
		return await APIService.getData<SubcontractorsDetails>(
			APICONFIG.GET_CONTRACTORS_FPC_LIST + `?membership_no=${memberShipNo}`
		);
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['SubcontractorsDetails'],
		queryFn: fetchContractorDetail,
		enabled: !!memberShipNo,
		gcTime: 0,
		staleTime: 0,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
