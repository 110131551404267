import { memo, ReactElement, useState } from 'react';
import { Dropdown, MultiselectDropdown } from '@components/common';
import { useGetMemberByFilterOptions } from '@services/hooks/endusers';
import { IMemberListDropDownProps } from '@components/common/formControl/dropdown/components';
import { useDebounce } from '@hooks/useDebounce';

const MemberListDropDown = <T,>(props: IMemberListDropDownProps<T>): ReactElement => {
	const {
		formik,
		contractorclass = [],
		fieldName,
		labelName,
		fieldRequired,
		isMultiSelect = true,
		fieldDisable = false,
		fieldPlaceHolder,
		handelKeyDown: onChange,
		isSubcontractor = false,
	} = props;

	const [memberFilterOption, setMemberFilterOption] = useState<string>('');
	const { modifiedResult: memberList, fetchMore: memberFetchMore } = useGetMemberByFilterOptions({
		searchValue: memberFilterOption,
		contractorclass: contractorclass,
		subcontractor: isSubcontractor,
	});

	const handleKeyDown = useDebounce(
		(e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>): void => {
			const target = e.target as HTMLInputElement;
			const searchValue: string = target?.value?.includes('-')
				? target?.value?.split('-')[1]?.trim()
				: target?.value?.trim();
			setMemberFilterOption(searchValue);
		}
	);

	if (!isMultiSelect) {
		return (
			<Dropdown
				id={fieldName}
				name={fieldName}
				label={labelName}
				onBlur={(e) => {
					formik.handleBlur(e);
					handleKeyDown(e);
				}}
				title={formik.values[fieldName]}
				error={formik.touched[fieldName] && formik.errors[fieldName] ? formik.errors[fieldName].toString() : null}
				options={memberList ?? []}
				setFieldValue={formik.setFieldValue}
				disabled={fieldDisable}
				handelKeyDownProp={handleKeyDown}
				onChange={onChange}
				listFetching={true}
				placeholder={fieldPlaceHolder}
				blurValidate
				required={fieldRequired}
				listFetchMore={memberFetchMore}
			/>
		);
	}

	return (
		<MultiselectDropdown
			id={fieldName}
			options={memberList ?? []}
			name={fieldName}
			label={labelName}
			values={formik.values[fieldName]}
			setFieldValue={formik.setFieldValue}
			fetchMore={memberFetchMore}
			handKeyDown={handleKeyDown}
			error={formik.touched[fieldName] && formik.errors[fieldName] ? formik.errors[fieldName].toString() : null}
			required={fieldRequired}
			disabled={fieldDisable}
		/>
	);
};

export default memo(MemberListDropDown);
