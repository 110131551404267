import { ReactElement, useCallback } from 'react';
import { IFactory } from '.';
import { NavLink, useNavigate } from 'react-router-dom';

export const Factory = (props: IFactory): ReactElement => {
	const { factory, factoryIndex } = props;
	const navigate = useNavigate();

	const handleNavigate = useCallback((): void => {
		navigate('/factory-visit/view-factory-visit', {
			state: {
				factoryId: factory?.id,
			},
		});
	}, [factory, navigate]);

	const handleNavigateCheck = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
			e.preventDefault();
			handleNavigate();
		},
		[handleNavigate]
	);

	return (
		<li key={factory?.id} className={`d-col d-col-2`}>
			<NavLink to="/" aria-label="sites" id="visit-user-form" onClick={handleNavigateCheck}>
				<span className="new-site-icon">
					<span className="icon-hammer icon"></span>
					<span className="site-count h5">{factoryIndex + 1}</span>
				</span>
				<span className="site-content">
					{factory?.factory_name && (
						<span className="h4" title={factory?.factory_name}>
							{factory?.factory_name}
						</span>
					)}
					{factory?.factory_location && (
						<address className="site-address h5" title={factory?.factory_location}>
							{factory?.factory_location}
						</address>
					)}
				</span>
				<span className="add-site-icon">
					<span className="icon-plus"></span>
				</span>
			</NavLink>
		</li>
	);
};
