import { ReactElement, useMemo } from 'react';
import { IDashboardPowerBi, scoreCardLinks } from '.';
import { NavLink } from 'react-router-dom';
import { useGetRxDb } from '@hooks/getRxdbData';
import { ProfileData } from '@services/hooks/profile';

export const PowerBi = (props: IDashboardPowerBi): ReactElement => {
	const { id } = props;
	const { result: profileData } = useGetRxDb<ProfileData>('profile');

	const scoreCardLink = useMemo(() => {
		const role = profileData[0]?.usertype_c;
		return scoreCardLinks.find((link) => link.role === role)?.link ?? '';
	}, [profileData]);

	return (
		<section key={id} className="power-bi-button-section">
			<div className="container">
				<div className="power-bi-button-wrapper">
					<ul className="fwd-wrapper">
						<li>
							<NavLink to={scoreCardLink} target="_blank" aria-label="link">
								<p>Score Card Link</p>
								<span className="icon-link"></span>
							</NavLink>
						</li>
						<li>
							<NavLink to={scoreCardLink} target="_blank" aria-label="link">
								<p>Field Working Days</p>
								<span className="icon-link" />
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};
