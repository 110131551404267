import { create } from 'zustand';

interface EndUseStore {
	adoptedContractorModuleFilter: FilterObject | null;
	leadUserModuleFilter: FilterObject | null;
	allEndUserFilter: FilterObject | null;
	updateAdoptedContractorModuleFilter: (data: FilterObject) => void;
	updateLeadUserModuleFilter: (data: FilterObject) => void;
	updateAllEndUserFilter: (data: FilterObject) => void;
}
export interface FilterObject {
	dateOfVisit: { flagged: boolean; startDate: Date | null; endDate: Date | null };
	visitStatus: { flagged: boolean; visitStatus: string };
	customToggle: { flagged: boolean; data: { value: string }[]; toggleData: string[] };
	checkSecondary: { flagged: boolean; name: string; data: { value: string }[]; secondaryData: string[] }[];
	kycStatus: { flagged: boolean; kycStatus: string };
	customSelect: { flagged: boolean; customData: { id: number; value: string }[] };
	singleDropDown: {
		flagged: boolean;
		name: string;
		data: { id: number; value: string }[];
		dropDownData: string;
	};
	largeContractor: {
		flagged: boolean;
		name: string;
		dropDownData: string;
		value: string;
	};
}

export interface SelectorFilter {
	createddate?: { $gte: string; $lte: string };
	visitstatus?: { $eq: string };
	contractorclass?: { $in: string[] };
	kyc_id_proof_verification?: { $eq: string };
	clubname?: { $in: string[] };
	contractorconsumption?: { $in: string[] };
	is_a_ob?: { $eq: boolean };
	is_de_growing?: { $eq: boolean };
	is_zbc?: { $eq: boolean };
	source?: { $eq: string };
	is_adopted_by?: { $eq: boolean };
	parent_member_id_c?: {
		$exists: boolean;
		$ne: null;
		$eq?: string;
	};
}

const initialState = {
	adoptedContractorModuleFilter: null,
	leadUserModuleFilter: null,
	allEndUserFilter: null,
};

export const EndUserFilterStore = create<EndUseStore>((set) => ({
	...initialState,
	updateAdoptedContractorModuleFilter: (adoptedContractorModuleFilter: FilterObject): void =>
		set(() => ({ adoptedContractorModuleFilter: adoptedContractorModuleFilter })),
	updateLeadUserModuleFilter: (leadUserModuleFilter: FilterObject): void =>
		set(() => ({ leadUserModuleFilter: leadUserModuleFilter })),
	updateAllEndUserFilter: (allEndUserFilter: FilterObject): void => set(() => ({ allEndUserFilter: allEndUserFilter })),
}));
