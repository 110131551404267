import { ReactElement, useMemo, useCallback } from 'react';
import { BankingDetailsCard, IDashboardQuarterSummary, ITargetValues, SummaryCard, TotalSiteCount } from '.';
import { useQuarterSummaryQuery } from '@services/hooks/dashboard/useQuarterSummaryQuery';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useMeetingFilterStore } from '@store/meetingFilterStore';
import { meetingInitialValues } from '@components/meetingModule/components/meeting-filter';
import { EndUserFilterStore } from '@store/memberList';
import { getQuarterStartEndDate } from '@helper/utils';
import { initialAllEndUsersFilter } from '@components/members/components/allEndUserList';
import { useSiteSummaryListingStore } from '@store/siteSummaryListingFilterStore';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';
import { useFactoryVisitListingStore } from '@store/factoryVisitListStore';
import { useGetRxDb } from '@hooks/getRxdbData';
import { ProfileData } from '@services/hooks/profile';
import { useEndUserNormsCount } from '@services/hooks/dashboard/useEndUserNormsCount';

export const QuarterSummary = (props: IDashboardQuarterSummary): ReactElement => {
	const { id } = props;
	const { data, error, isFetching } = useQuarterSummaryQuery();
	const { updateFilter } = useMeetingFilterStore();
	const { updateFilter: siteLeadUpdate, allSiteSummaryFilter } = useSiteSummaryListingStore();
	const { updateFilter: factoryFilterUpdate, factoryVisitFilter } = useFactoryVisitListingStore();
	const { updateAllEndUserFilter } = EndUserFilterStore();
	const checkMeetingPermissions = useComponentLevelAccessCheck('meeting-banner');
	const checkMemberModulePermissions = useComponentLevelAccessCheck('member-module');
	const checkBDEFactoryVisitPermissions = useComponentLevelAccessCheck('bde-factory-visit');
	const checkTotalOutOffViewPermissions = useComponentLevelAccessCheck('total-out-off-view');
	const checkTotalOutOffViewSitePermissions = useComponentLevelAccessCheck('total-out-off-view-site');
	const { data: quarterData } = useEndUserNormsCount();
	const { result: profile } = useGetRxDb<ProfileData>('profile');
	const { totalCount } = useGetRxDb('club');

	const handleCardRedirect = (): void => {
		const { start, end } = getQuarterStartEndDate(new Date());
		updateFilter('allMeetingFilter', {
			...meetingInitialValues,
			meetingStatus: [
				{
					id: 1,
					value: 'Completed Meeting',
				},
			],
			meetingType: 'FCC PRODUCT AWARENESS MEET',
			filter_date: [start, end],
		});
	};

	const handleSiteRedirection = (): void => {
		const { start, end } = getQuarterStartEndDate(new Date());
		siteLeadUpdate({
			...allSiteSummaryFilter,
			current_stage_of_site_c: 'Site Visited',
			lastactivitydate: [start, end],
		});
	};
	const handleFactoryRedirection = useCallback((): void => {
		const { start, end } = getQuarterStartEndDate(new Date());
		factoryFilterUpdate({
			...factoryVisitFilter,
			visitDate: [start, end],
		});
	}, [factoryFilterUpdate, factoryVisitFilter]);

	const handleAllEndUserFilter = (name: string): void => {
		const { start, end } = getQuarterStartEndDate(new Date());
		updateAllEndUserFilter({
			...initialAllEndUsersFilter,
			...(name === 'date' && {
				dateOfVisit: {
					...initialAllEndUsersFilter.dateOfVisit,
					startDate: start,
					endDate: end,
				},
			}),
			...(name === 'zbc' && {
				customToggle: { ...initialAllEndUsersFilter.customToggle, toggleData: ['ZBC'] },
			}),
			...(name === 'dgc' && {
				customToggle: { ...initialAllEndUsersFilter.customToggle, toggleData: ['Degrowing'] },
			}),
		});
	};

	const targetValues = useMemo<ITargetValues>(() => {
		const meetingCount = Math.ceil((totalCount ?? 0) / 2);
		return {
			meetingTarget: meetingCount,
			userTarget: quarterData?.target_for_quarter_count ?? 0,
			siteVisitTarget: TotalSiteCount[profile[0]?.usertype_c],
		};
	}, [profile, quarterData, totalCount]);

	return (
		<Wrapper isLoaderVisible={isFetching} isError={error}>
			<section key={id} className="quarter-summary-card">
				<div className="container">
					<span className="h3 cmn-heading">
						<span>Quarter Summary</span>
					</span>

					<div className="summary-card">
						{checkMeetingPermissions && (
							<SummaryCard
								heading="Total Meetings"
								urlPath="/meetings/all-meetings"
								value={data?.total_meeting}
								summaryLeftIcon="icon-coffee"
								targetValue={targetValues.meetingTarget}
								onCardClick={handleCardRedirect}
								checkTotalOutOffViewPermissions={checkTotalOutOffViewPermissions}
							/>
						)}
						{checkMemberModulePermissions && (
							<>
								<SummaryCard
									heading="Total Site Visits"
									urlPath="/site-summary"
									value={data?.site_visits}
									summaryLeftIcon="icon-hammer"
									targetValue={targetValues.siteVisitTarget}
									onCardClick={handleSiteRedirection}
									checkTotalOutOffViewPermissions={checkTotalOutOffViewSitePermissions}
								/>
								<SummaryCard
									heading="Total Users Added"
									urlPath="/end-users/all"
									value={data?.total_user_enrollment}
									summaryLeftIcon="icon-user"
									targetValue={targetValues.userTarget}
									checkTotalOutOffViewPermissions={checkTotalOutOffViewPermissions}
									onCardClick={() => handleAllEndUserFilter('date')}
								/>
							</>
						)}
						{!checkBDEFactoryVisitPermissions && (
							<SummaryCard
								heading="Total Factory Visits"
								urlPath="/factory-visit"
								value={data?.factory_visits}
								summaryLeftIcon="icon-factory"
								onCardClick={handleFactoryRedirection}
							/>
						)}
					</div>

					{checkMemberModulePermissions && (
						<div className="zero-banking-user-card">
							<ul className="banking-details-list">
								<BankingDetailsCard
									title="0 Banking User"
									redirectTo="/end-users/all"
									value={data?.zero_banking_users}
									onBankingCardClick={() => handleAllEndUserFilter('zbc')}
								/>
								<BankingDetailsCard
									title="De-growing Users"
									redirectTo="/end-users/all"
									value={data?.de_growing_users}
									onBankingCardClick={() => handleAllEndUserFilter('dgc')}
								/>
							</ul>
						</div>
					)}
				</div>
			</section>
		</Wrapper>
	);
};
