import { ReactElement } from 'react';
type FCPType = {
	track: boolean;
	pwg_ytd: number;
	balance: number;
	openModal: () => void;
	label?: string;
};
export const FCPStatus = ({
	track,
	pwg_ytd,
	balance,
	openModal,
	label = 'PWG YTD Achieved',
}: FCPType): ReactElement => {
	return (
		<div className="scheme-discussion-details">
			<div className="tier-status">
				<span className="title">FPC Tier Status</span>
				<span className={`status ${!track ? 'danger-tag' : ''}`}>
					<span className="dot"></span>
					<span className="track"> {track ? 'ON' : 'OFF'} Track</span>
				</span>
			</div>

			<span className="packaging-details">
				{label}: {pwg_ytd ? `${pwg_ytd} Kg` : '-'}
			</span>
			<div className="sub-contractor-content">
				<span className="packaging-details">Balance: {balance ? `${balance} Kg` : '-'}</span>
				<button aria-label="SubContractor Details" onClick={openModal} title="SubContractor Details">
					<span className="icon-arrow-right-circle"></span>
				</button>
			</div>
		</div>
	);
};
