import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';

export interface SubcontractorsData {
	membership_no: string | number | null | undefined;
	end_user_membershipno: string | null | number | undefined;
}
interface IDealerVisitData {
	mutate: UseMutateFunction<SubcontractorsData[], Error, SubcontractorsData[], unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const UpdateSubcontractorsDetailMutation = (
	membershipNo: string | number | null | undefined,
	isEdit?: boolean
): IDealerVisitData => {
	const createSubcontractorsDetail = async (visitData: SubcontractorsData[]): Promise<SubcontractorsData[]> => {
		return await APIService.postData<SubcontractorsData[]>(`${APICONFIG.ADD_SUBCONTRACTORS_DETAIL}`, visitData);
	};
	const updateSubcontractorsDetail = async (visitData: SubcontractorsData[]): Promise<SubcontractorsData[]> => {
		return await APIService.postData<SubcontractorsData[]>(
			APICONFIG.ADD_SUBCONTRACTORS_DETAIL + `?membership_no=${membershipNo}`,
			visitData
		);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: isEdit ? updateSubcontractorsDetail : createSubcontractorsDetail,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
