import { ReactElement, useCallback, useEffect, useState } from 'react';
import Checkbox from '@components/common/formControl/checkbox/checkbox';
import { IProductRecommendation, products } from '.';
import { RecommendedProductData } from '../contractorSites';
import { ToastType, useTosterStore } from '@store/toster';
import { useAddUpdateRecommendedProducts } from '@services/hooks/contractor360/useAddUpdateRecommendedProductsMutation';
import { useProductRecommendationQuery } from '@services/hooks/contractor360/useFetchRecommondedProducts';

export const ProductRecommendationModal = ({
	modalClose,
	onSubmit,
	membershipNo,
	isUpdate,
}: IProductRecommendation): ReactElement => {
	const { mutate: createAndUpdateRecommendedProducts } = useAddUpdateRecommendedProducts(isUpdate, membershipNo);
	const { mutate: fetchProductRecommendationList } = useProductRecommendationQuery();
	const { setToaster } = useTosterStore();
	const [productData, setProductData] = useState<RecommendedProductData[]>(
		products.map((product) => ({
			product: product.product,
			product_name: product.product_name,
			demoed_in_last_six_months: false,
			purchased_in_last_six_months: false,
			membership_no: membershipNo,
		}))
	);

	const transformProductRecommendationData = (
		data: RecommendedProductData[],
		membershipNo: string | null
	): RecommendedProductData[] => {
		return data.map(
			({
				product_name,
				demoed_in_last_six_months,
				purchased_in_last_six_months,
				membership_no,
				product,
				id,
			}: RecommendedProductData) => ({
				product,
				product_name,
				demoed_in_last_six_months: demoed_in_last_six_months || false,
				purchased_in_last_six_months: purchased_in_last_six_months || false,
				membership_no: membership_no ?? membershipNo,
				id,
			})
		);
	};

	useEffect(() => {
		if (isUpdate && membershipNo) {
			fetchProductRecommendationList(
				{
					membershipNo: membershipNo,
				},
				{
					onSuccess: (productRecommendations) => {
						if (productRecommendations && productRecommendations.length > 0) {
							const transformedData = transformProductRecommendationData(productRecommendations, membershipNo);
							setProductData(transformedData);
						}
					},
					onError: (error) => {
						setToaster(true, ToastType.error, error?.message);
					},
				}
			);
		}
	}, [isUpdate, membershipNo, setToaster]);

	const handleSubmitData = useCallback(
		(submitData: RecommendedProductData[]) => {
			createAndUpdateRecommendedProducts(submitData, {
				onSuccess: () => {
					setToaster(
						true,
						ToastType.success,
						isUpdate ? 'Product recommendation updated successfully' : 'Product recommendation added successfully'
					);
					onSubmit();
				},
				onError: (error) => {
					setToaster(true, ToastType.error, error?.message);
				},
			});
		},
		[isUpdate]
	);

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, productId: string, type: string): void => {
		const { checked } = e.target;
		setProductData((prev: RecommendedProductData[]) =>
			prev.map((product) =>
				product.product_name === productId
					? {
							...product,
							[type]: checked,
						}
					: product
			)
		);
	};

	const handleSubmit = (): void => {
		const submitData = productData.map(({ product, demoed_in_last_six_months, purchased_in_last_six_months, id }) => ({
			product,
			demoed_in_last_six_months,
			purchased_in_last_six_months,
			membership_no: membershipNo,
			id,
		}));
		handleSubmitData(submitData);
	};
	return (
		<>
			<div className="details-history-card-table">
				<table className="history-table mt-4 text-base md:text-lg">
					<thead>
						<tr>
							<th className="popup-table-head">Product</th>
							<th className="popup-table-head">Demoed in last 6 months</th>
							<th className="popup-table-head">Purchased in last 6 months</th>
						</tr>
					</thead>
					<tbody>
						{productData.length > 0 &&
							productData.map((product: RecommendedProductData) => (
								<tr key={product.product_name}>
									<td>{product.product_name}</td>
									<td className="center-block">
										<Checkbox
											id={`${product.product_name}_demoed`}
											name={`${product.product_name}_demoed`}
											checked={product.demoed_in_last_six_months}
											onChange={(e) =>
												handleCheckboxChange(e, product?.product_name ?? '', 'demoed_in_last_six_months')
											}
											type="checkbox"
										/>
									</td>
									<td className="center-block">
										<Checkbox
											id={`${product.product_name}_purchased`}
											name={`${product.product_name}_purchased`}
											checked={product.purchased_in_last_six_months}
											onChange={(e) =>
												handleCheckboxChange(e, product?.product_name ?? '', 'purchased_in_last_six_months')
											}
											type="checkbox"
										/>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
			<div className="btn-wrapper">
				<button type="button" aria-label="cancel button" className="btn btn-primary btn-cancel" onClick={modalClose}>
					Cancel
				</button>
				<button type="button" onClick={handleSubmit} aria-label="confirmation button" className="btn-primary btn">
					Confirm
				</button>
			</div>
		</>
	);
};
