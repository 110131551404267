import { initialFilterObject, useFilterStore } from '@store/index';
import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getDatabase } from '@rxdb/config/databaseConfig';
import { ClubDetails } from '@services/hooks/enduser-details';
import { MultiDateRangePicker } from '@components/common';
import { Dropdown, MultiselectDropdown } from '../formControl';
import { useFormik } from 'formik';
import { SUPERVISOR_ALLOWED_CLASS } from '@config/constant';
import { MemberListDropDown } from '@components/common/formControl/dropdown/components';
import { useGetMemberByFilterOptions } from '@services/hooks/endusers';
export interface Filter {
	setFilterOpen: () => void;
}
export interface FilterObject {
	visitStatus: string;
	kycStatus: string;
	selectedValues: string[];
	secondarySelectedValues: string[][];
	club: string;
	club_data: { id: number; value: string }[];
	multiSelect: { id: number; value: string }[];
	filter_date: [Date | null, Date | null];
	largeContractor: string;
}

export const Filter = (props: Filter): ReactElement => {
	const { setFilterOpen } = props;
	const { pathname } = useLocation();
	const { filterObject, updateFilterObject } = useFilterStore((state) => state);
	const initialValues: FilterObject = {
		visitStatus: '',
		kycStatus: '',
		selectedValues: [],
		secondarySelectedValues: new Array(filterObject.checkSecondary.length).fill([]),
		club: '',
		club_data: [],
		multiSelect: [],
		filter_date: [null, null],
		largeContractor: '',
	};
	const formik = useFormik({
		initialValues,
		onSubmit: () => {},
	});

	const handleChildClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.stopPropagation();
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, value: string): void => {
		const isChecked = e.target.checked;
		const updatedValues = isChecked
			? [...formik.values.selectedValues, value]
			: formik.values.selectedValues.filter((item) => item !== value);
		formik.setFieldValue('selectedValues', updatedValues);
	};

	const handleVisitStatusChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		formik.setFieldValue('visitStatus', e.target.value);
	};
	const handleKycStatusChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		formik.setFieldValue('kycStatus', e.target.value);
	};
	const { result } = useGetMemberByFilterOptions({
		searchValue: formik?.values?.largeContractor?.split(' - ')[1],
		contractorclass: [],
	});

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault();
		updateFilterObject({
			...filterObject,
			kycStatus: { ...filterObject.kycStatus, kycStatus: formik.values.kycStatus },
			visitStatus: { ...filterObject.visitStatus, visitStatus: formik.values.visitStatus },
			customToggle: { ...filterObject.customToggle, toggleData: formik.values.selectedValues },
			checkSecondary: filterObject.checkSecondary.map((item, index) => {
				return {
					...item,
					secondaryData: formik.values.secondarySelectedValues[index],
				};
			}),
			customSelect: { ...filterObject.customSelect, customData: formik.values.multiSelect },
			dateOfVisit: {
				...filterObject.dateOfVisit,
				startDate: formik.values.filter_date[0],
				endDate: formik.values.filter_date[1],
			},
			singleDropDown: { ...filterObject.singleDropDown, dropDownData: formik.values.club },
			largeContractor: {
				...filterObject.largeContractor,
				dropDownData: formik.values.largeContractor,
				value: result[0]?._data?.membershipno,
			},
		});
		setFilterOpen();
	};

	const getDataFromIndexDb = async (): Promise<void> => {
		try {
			const db = await getDatabase();
			const result = await db.club.find().exec();
			await formik.setFieldValue('club_data', []);
			if (result?.length > 0) {
				const tempData: ClubDetails[] = result.map((ele: { _data: ClubDetails[] }) => ele?._data);
				await formik.setFieldValue(
					'club_data',
					tempData?.map((item) => {
						return { id: item.id, value: item.name };
					})
				);
			}
		} catch (error) {
			await formik.setFieldValue('club_data', []);
		}
	};

	useEffect(() => {
		getDataFromIndexDb();
		formik.setFieldValue('visitStatus', filterObject.visitStatus.visitStatus);
		formik.setFieldValue('kycStatus', filterObject.kycStatus.kycStatus);
		formik.setFieldValue('selectedValues', filterObject.customToggle.toggleData);
		formik.setFieldValue(
			'secondarySelectedValues',
			filterObject.checkSecondary.map((item) => item.secondaryData)
		);
		formik.setFieldValue('multiSelect', filterObject.customSelect.customData);
		formik.setFieldValue('dateOfVisit', {
			startDate: filterObject.dateOfVisit.startDate,
			endDate: filterObject.dateOfVisit.endDate,
		});
		formik.setFieldValue('club', filterObject.singleDropDown.dropDownData);
		formik.setFieldValue('filter_date', [filterObject.dateOfVisit.startDate, filterObject.dateOfVisit.endDate]);
		formik.setFieldValue('largeContractor', filterObject.largeContractor.dropDownData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterObject]);

	const handleFilterResetHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
		e.preventDefault();
		setFilterOpen();
		updateFilterObject({
			...initialFilterObject,
			dateOfVisit: { flagged: filterObject.dateOfVisit.flagged, startDate: null, endDate: null },
			customToggle: {
				flagged: filterObject.customToggle.flagged,
				data: filterObject.customToggle.data,
				toggleData: [],
			},
			visitStatus: { flagged: filterObject.visitStatus.flagged, visitStatus: '' },
			checkSecondary: filterObject.checkSecondary.map((item) => ({
				flagged: item.flagged,
				name: item.name,
				data: item.data,
				secondaryData: [],
			})),
			customSelect: { flagged: filterObject.customSelect.flagged, customData: [] },
			singleDropDown: {
				flagged: filterObject.singleDropDown.flagged,
				name: filterObject.singleDropDown.name,
				data: filterObject.singleDropDown.data,
				dropDownData: '',
			},
			kycStatus: { flagged: filterObject.kycStatus.flagged, kycStatus: '' },
			largeContractor: {
				flagged: filterObject.largeContractor.flagged,
				dropDownData: '',
				name: '',
				value: '',
			},
		});
	};

	useEffect(() => {
		const ele = document.getElementById('filter-main-wrapper') as HTMLElement;
		const overlay = document.getElementById('overlay') as HTMLElement;
		document.body.classList.add('overflow-hidden');
		overlay.classList.add('show');

		if (ele.className.includes('show')) {
			ele.classList.remove('show');
		}

		setTimeout(() => {
			ele.classList.add('show');
		}, 0);
	}, []);

	const handleItemClick = (index: number, value: string): void => {
		const toggleValueInArray = (array: string[], value: string): string[] => {
			if (array.includes(value)) {
				return array.filter((item) => item !== value);
			}
			return [...array, value];
		};

		const newState = formik.values.secondarySelectedValues.map((nestedArray, i) => {
			if (i === index) {
				return toggleValueInArray(nestedArray, value);
			}
			return nestedArray;
		});

		formik.setFieldValue('secondarySelectedValues', newState);
	};

	const isAllStateFilled = (): boolean => {
		return !(
			formik.values.visitStatus ||
			formik.values.kycStatus ||
			formik.values.selectedValues.length ||
			formik.values.secondarySelectedValues.some((item) => item.length) ||
			formik.values.club.length ||
			formik.values.filter_date.every((item) => item?.toString().length) ||
			formik.values.multiSelect.length
		);
	};

	return (
		<div
			role="button"
			tabIndex={0}
			className="filter-drawer-wrapper filter-contractor-drawer"
			id="filter-main-wrapper"
			onClick={setFilterOpen}
		>
			<div className="container">
				<button
					type="button"
					aria-label="filter popup"
					className="filter-sort-icon icon-filter"
					onClick={setFilterOpen}
				></button>
				<div
					role="button"
					tabIndex={0}
					className="filter-drawer-inner-wrapper filter"
					onClick={(e) => handleChildClick(e)}
				>
					<div className="filter-title">
						<p className="h2">Filter</p>
						<a href={pathname} aria-label="Clear All" onClick={handleFilterResetHandler} hidden={isAllStateFilled()}>
							Clear All
						</a>
					</div>
					<form>
						<div className="form-fieldset row">
							{filterObject.visitStatus.flagged && (
								<div className="d-col">
									<span className="form-title h4">
										<span className="icon-hammer"></span>Visit Status (FTQ)
									</span>
									<ul className="custom-check primary">
										<li className="custom-check-item">
											<label>
												<input
													type="radio"
													name="visitStatus"
													value="Visited"
													checked={formik.values.visitStatus === 'Visited'}
													onChange={handleVisitStatusChange}
												/>
												<span className="h4">Visited</span>
											</label>
										</li>
										<li className="custom-check-item">
											<label>
												<input
													type="radio"
													name="visitStatus"
													value="Not-Visited"
													checked={formik.values.visitStatus === 'Not-Visited'}
													onChange={handleVisitStatusChange}
												/>
												<span className="h4">Not-Visited</span>
											</label>
										</li>
									</ul>
								</div>
							)}

							{filterObject.customToggle.flagged && (
								<div className="d-col">
									<ul className="custom-toggle-wrapper">
										{filterObject.customToggle.data.map((item) => {
											return (
												<li key={item.value}>
													<span className="form-title h4">
														<span className="icon-user"></span>
														{item.value}
													</span>
													<ul className="custom-toggle">
														<li className="custom-toggle-item">
															<input
																type="checkbox"
																id={`${item.value}`}
																onChange={(e) => handleCheckboxChange(e, item.value)}
																checked={formik.values.selectedValues.includes(item.value)}
															/>
															<label htmlFor={`${item.value}`}>
																<span className="visually-hidden">{item.value}</span>
															</label>
														</li>
													</ul>
												</li>
											);
										})}
									</ul>
								</div>
							)}

							{filterObject?.largeContractor?.flagged && formik?.values?.selectedValues?.includes('Sub Contractor') && (
								<div className="d-col">
									<span className="form-title h4">
										<span className="icon-user"></span>
										{filterObject.largeContractor.name}
									</span>
									<ul className="custom-toggle-wrapper">
										<MemberListDropDown
											formik={formik}
											fieldName={'largeContractor'}
											contractorclass={SUPERVISOR_ALLOWED_CLASS}
											isMultiSelect={false}
										/>
									</ul>
								</div>
							)}

							{filterObject?.checkSecondary?.map(
								(item, index) =>
									item.flagged && (
										<div className="d-col" key={item.name}>
											<span className="form-title h4">
												<span className="icon-punch"></span>
												{item.name}
											</span>
											<ul className="custom-check secondary">
												{item.data.map((item) => {
													return (
														<li className="custom-check-item" key={item.value}>
															<label>
																<input
																	type="checkbox"
																	value={item.value}
																	onChange={() => handleItemClick(index, item.value)}
																	checked={formik.values.secondarySelectedValues[index].includes(item.value)}
																/>
																<span className="h4">
																	{item.value}
																	<span className="badge-parent">
																		<span className="green-badge"></span>
																	</span>
																</span>
															</label>
														</li>
													);
												})}
											</ul>
										</div>
									)
							)}

							{filterObject.kycStatus.flagged && (
								<div className="d-col">
									<span className="form-title h4">
										<span className="icon-hammer"></span>KYC Status
									</span>
									<ul className="custom-check primary">
										<li className="custom-check-item">
											<label>
												<input
													type="radio"
													value="Verified"
													checked={formik.values.kycStatus === 'Verified'}
													onChange={handleKycStatusChange}
												/>
												<span className="h4">Verified</span>
											</label>
										</li>
										<li className="custom-check-item">
											<label>
												<input
													type="radio"
													value="Not-Verified"
													checked={formik.values.kycStatus === 'Not-Verified'}
													onChange={handleKycStatusChange}
												/>
												<span className="h4">Not-Verified</span>
											</label>
										</li>
									</ul>
								</div>
							)}

							{filterObject.dateOfVisit.flagged && (
								<div className="d-col">
									<span className="form-title h4">
										<span className="icon-date"></span>Date of Creation
									</span>
									<MultiDateRangePicker
										id="filter_date"
										name="filter_date"
										value={formik.values.filter_date}
										formik={formik}
										maxDate={new Date()}
									/>
								</div>
							)}

							{filterObject.singleDropDown.flagged && (
								<div className="d-col">
									<span className="form-title h4">
										<span className="icon-shield"></span>
										{filterObject.singleDropDown.name}
									</span>

									<Dropdown
										name="club"
										id="club"
										title={formik.values.club}
										options={filterObject.singleDropDown.data}
										setFieldValue={formik.setFieldValue}
									/>
								</div>
							)}

							{filterObject.customSelect.flagged && (
								<div className="d-col">
									<span className="form-title h4">
										<span className="icon-shield"></span>Club
									</span>
									<MultiselectDropdown
										options={formik.values.club_data}
										name={'multiSelect'}
										values={formik.values.multiSelect}
										setFieldValue={formik.setFieldValue}
									/>
								</div>
							)}
						</div>
					</form>

					<div className="d-col submit">
						<button
							type="submit"
							className="btn btn-primary btn-medium filter-sort-submit-btn"
							onClick={(e) => {
								handleSubmit(e);
							}}
							disabled={isAllStateFilled()}
						>
							Submit
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
