export * from './useHandleProductRecommendation';
import { ContractorStatus } from '../contractorSites';
export interface IProductRecommendation {
	modalClose: () => void;
	onSubmit: () => void;
	membershipNo?: string | null;
	productRecommendationData?: ContractorStatus;
	isUpdate: boolean;
}

interface IProduct {
	product: string;
	product_name: string;
}

export const products: IProduct[] = [
	{ product: 'FD761348', product_name: 'Fevicol Ezeespray' },
	{ product: 'F2971281', product_name: 'Marinekoat' },
	{ product: 'FD171132', product_name: 'Fevicol Heatx' },
	{ product: 'FD761349', product_name: 'Fevicol Plastilok' },
	{ product: 'FD761319', product_name: 'Fevicol Probond Edgelok' },
	{ product: 'FB311341', product_name: 'Fevicol Nailfree Ultra' },
	{ product: 'F2971073', product_name: 'Terminator' },
	{ product: 'FD761350', product_name: 'Fevicol Relam' },
];
export interface IHandleProductRecommendation {
	membershipno?: string | null;
	banking_mtd_kgs?: number;
	contractorclass?: string;
}

export interface IUseHandleProductRecommendation {
	handleProductRecommendation: (fn: () => void) => void;
	closeProductRecommendation: () => void;
	productRecommendationData?: ContractorStatus;
	isProductRecommendationOpen: {
		modalOpen: boolean;
		isUpdate: boolean;
	};
	setIsProductRecommendationOpen: React.Dispatch<
		React.SetStateAction<{
			modalOpen: boolean;
			isUpdate: boolean;
		}>
	>;
}
