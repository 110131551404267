import { create } from 'zustand';
export interface FactoryVisitListFilterObject {
	competition_site: boolean;
	visitDate: [Date | null, Date | null];
}

export interface FactoryVisitListingStore {
	factoryVisitFilter: FactoryVisitListFilterObject;
	updateFilter: (filterObject: FactoryVisitListFilterObject) => void;
	resetFactoryVisitFilter: () => void;
}

const initialState: { factoryVisitFilter: FactoryVisitListFilterObject } = {
	factoryVisitFilter: {
		competition_site: false,
		visitDate: [null, null],
	},
};

export const useFactoryVisitListingStore = create<FactoryVisitListingStore>((set) => ({
	...initialState,
	updateFilter: (filterObject): void => {
		set((state) => ({
			...state,
			factoryVisitFilter: { ...state.factoryVisitFilter, ...filterObject },
		}));
	},
	resetFactoryVisitFilter: (): void => {
		set((state) => ({
			...state,
			factoryVisitFilter: {
				competition_site: false,
				visitDate: [null, null],
			},
		}));
	},
}));
