import { FormikValues, useFormik } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { defaultBasicDetailFormInitialValues } from '.';
import { SubmitButton } from '@components/common';
import { useValidation } from '@hooks/validations';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBasicDetailsMutation } from '@services/hooks/enduser-details/useBasicDetailsMutation';
import { useActiveGliderClick } from '@hooks/useActiveGlider';
import { useBasicDetailsQuery } from '@services/hooks/enduser-details/useBasicDeailsQuery';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { BasicDetails, TownList } from '@services/hooks/enduser-details';
import { useEndUserStore } from '@store/enduser';
import { UpperFormSection } from './upperFormSection';
import { MiddleFormSection } from './middleFormSection';
import { BottomFormSection } from './bottomFormSection';
import { compareLocalAddresses, compareWorkAddresses, formatDate, get18YearsAgo, getTownInfo } from '@helper/utils';
import { usePhoneNumberValidateQuery } from '@services/hooks/enduser-details/usePhoneNumberValidateQuery';
import { useTownDetailsQuery } from '@services/hooks/enduser-details/useTownDetailsQuery';
import { useGetRxDb } from '@hooks/getRxdbData';
import { useLeadUserQuery } from '@services/hooks/endusers/leadUserQuery';
import { ToastType, useTosterStore } from '@store/toster';
import { SixMonthsConfirmationModal } from '@components/contractor360/components';
import { Modal } from '@components/common/modal/modal';
import { useEndUserProfileStore } from '@store/endUserProfileStore';
import { numberValueCheck, stringValueCheck } from '@helper/formControl';
import { useAllEndUserQuery } from '@services/hooks/endusers/allEndUserQuery';

export const BasicDetailsForm = (): ReactElement => {
	const nav = useNavigate();
	const { state } = useLocation();
	const { handleTabClick } = useActiveGliderClick();
	useTownDetailsQuery();
	const { result: towns, isFetching: townFetching } = useGetRxDb<TownList>('towns');
	const { basicDetailsValidationSchema } = useValidation();
	const { isEditing, setIsEditing, setActiveNavTab, isFormEdit, setFormId, formId, formRevalidate } = useEndUserStore();
	const { mutate, isPending: isBasicFormPending } = useBasicDetailsMutation(formId, isFormEdit);
	const {
		data: getBasicFormData,
		isFetching: isGetBasicFormFetching,
		error: isGetBasicFormError,
	} = useBasicDetailsQuery(formId, isFormEdit);
	const { refetch: leadUserRefetch } = useLeadUserQuery();
	const { refetch: refetchAllEndUser } = useAllEndUserQuery();
	const { setToaster } = useTosterStore();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { basicDetailsRevalidate, setKeyBaseValue: setEndUserKeyBaseValue } = useEndUserProfileStore();

	const formik = useFormik({
		initialValues: defaultBasicDetailFormInitialValues,
		onSubmit: () => {
			handleNext();
		},
		validationSchema: basicDetailsValidationSchema,
		validateOnMount: true,
		validate: async () => {
			const errors: Partial<FormikValues> = {};
			if (phoneValidationData?.data?.exists && phoneValidationData?.data?.message) {
				errors.primary_number = phoneValidationData?.data?.message;
			}

			if (secondaryNumberVlidationData?.data?.exists && secondaryNumberVlidationData?.data?.message) {
				errors.secondary_number = secondaryNumberVlidationData?.data?.message;
			}

			return errors;
		},
	});

	const { data: phoneValidationData, isFetching: isPhoneDetailsFetching } = usePhoneNumberValidateQuery(
		formik.values.primary_number,
		'primary_number'
	);
	const { data: secondaryNumberVlidationData } = usePhoneNumberValidateQuery(
		formik.values.secondary_number,
		'secondary_number'
	);

	const commonFormProps = {
		formik,
		isEditing,
		isFormEdit,
		getBasicFormData,
		formId: formId,
	};

	const townsList = useMemo(() => {
		if (towns?.length) {
			return towns?.map((t) => ({
				id: t.id,
				value: `${t.towncode_c} - ${t.towndescription_c}`,
			}));
		}
		return [];
	}, [towns]);

	const updateBasicDetails = useCallback(
		(payload: BasicDetails) => {
			mutate(payload, {
				onSuccess: (data) => {
					if (data.id) {
						leadUserRefetch?.();
						setEndUserKeyBaseValue('basicDetailsRevalidate', false);
						if (isFormEdit) refetchAllEndUser?.();
						const formTabs = isFormEdit ? [0, 1, 2] : [0, 1];
						const navigateUrl = `/${isFormEdit ? 'edit' : 'add'}-member-details/kyc-details`;
						setFormId(data.id);
						setActiveNavTab(formTabs);
						nav(navigateUrl, {
							state: {
								membershipNumber: state?.membershipNumber,
							},
						});
						handleTabClick(1);
					}
				},
				onError: (error) => {
					setToaster(true, ToastType.error, error?.message);
				},
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			handleTabClick,
			isFormEdit,
			mutate,
			nav,
			setActiveNavTab,
			setFormId,
			state?.membershipNumber,
			leadUserRefetch,
			setToaster,
		]
	);

	const handleNext = useCallback(() => {
		const townInfo = getTownInfo(towns, formik.values.permanent_town);
		const payload = {
			firstname: formik.values.first_name,
			middlename: formik.values.middle_name,
			lastname: formik.values.last_name,
			glue_consumption_per_month: Number(formik.values?.calculated_glue),
			monthlyconsumption_c: Number(formik.values?.average_glue),
			dateofbirth: formatDate(formik.values.birth_date),
			permanent_address1_c: formik.values.permanent_address,
			permanentstreet_c: formik.values.permanent_street,
			permanentzipcode_c: Number(formik.values.permanent_zipcode),
			permanentstate_c: formik.values.permanent_state,
			permanentcity_c: formik.values.permanent_city,
			permanent_address2_c: formik.values.local_address_chekbox
				? formik.values.permanent_address
				: formik.values.local_address,
			local_city_c: formik.values.local_address_chekbox ? formik.values.permanent_city : formik.values.local_city,
			local_state_c: formik.values.local_address_chekbox ? formik.values.permanent_state : formik.values.local_state,
			local_street_c: formik.values.local_address_chekbox ? formik.values.permanent_street : formik.values.local_street,
			local_pin_code_c: formik.values.local_address_chekbox
				? Number(formik.values.permanent_zipcode)
				: Number(formik.values.local_zipcode),
			permanent_address3_c: formik.values.work_address_chekbox
				? formik.values.permanent_address
				: formik.values.work_address,
			work_city_c: formik.values.work_address_chekbox ? formik.values.permanent_city : formik.values.work_city,
			work_street_c: formik.values.work_address_chekbox ? formik.values.permanent_street : formik.values.work_street,
			work_pin_code_c: formik.values.work_address_chekbox
				? Number(formik.values.permanent_zipcode)
				: Number(formik.values.work_zipcode),
			work_state_c: formik.values.work_address_chekbox ? formik.values.permanent_state : formik.values.work_state,
			religion: formik.values.religion,
			phone: formik.values.primary_number,
			AlternateMobileNo1: formik.values.secondary_number,
			language: formik.values.language,
			average_number_of_sites_in_a_year_c: Number(formik.values.sites_worked),
			competitionconsumption_c: Number(formik.values.competitionConsumption),
			weddinganniversary_c: formik.values.wedding_anniversary
				? formatDate(new Date(formik.values.wedding_anniversary))
				: null,
			email: formik.values.email,
			samaj: formik.values.samaj,
			town_code_c: townInfo?.towncode ?? '',
			town_name_c: townInfo?.townname ?? '',
			status: 'Kyc Details',
			additionenduser: {
				hobbies_c: 'dancing,singing',
			},
			total_year_of_experience_c: Number(formik.values.sites_competition),
		};
		updateBasicDetails(payload);
	}, [formik, towns, updateBasicDetails]);

	const handleBasicConfirmation = (fn: () => void): void => {
		if (basicDetailsRevalidate && formRevalidate) {
			setIsOpen(true);
			return;
		}
		fn();
	};

	const handleSubmitClick = (): void => {
		handleBasicConfirmation(() => {
			formik.handleSubmit();
		});
	};

	useEffect(() => {
		if (formik.values.unmarried_chekbox) formik.setFieldValue('wedding_anniversary', '');
		if (formik.values.samaj_checkbox) formik.setFieldValue('samaj', '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.unmarried_chekbox, formik.values.samaj_checkbox]);

	useEffect(() => {
		if (phoneValidationData?.data?.exists && phoneValidationData?.data?.message) {
			formik.setFieldError('primary_number', phoneValidationData.data.message);
			formik.setFieldTouched('primary_number', true, false);
		}
		if (secondaryNumberVlidationData?.data?.exists && secondaryNumberVlidationData?.data?.message) {
			formik.setFieldError('secondary_number', secondaryNumberVlidationData.data.message);
			formik.setFieldTouched('secondary_number', true, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneValidationData, secondaryNumberVlidationData]);

	useEffect(() => {
		if (townFetching) return;

		if (formId && getBasicFormData) {
			const isLocalAddressEqual = compareLocalAddresses(getBasicFormData);
			const isWorkAddressEqual = compareWorkAddresses(getBasicFormData);
			const updatedValue = {
				...formik.values,
				first_name: stringValueCheck(getBasicFormData?.firstname),
				middle_name: stringValueCheck(getBasicFormData.middlename),
				last_name: stringValueCheck(getBasicFormData.lastname),
				average_glue: numberValueCheck(getBasicFormData.monthlyconsumption_c),
				calculated_glue: numberValueCheck(getBasicFormData.glue_consumption_per_month),
				birth_date:
					getBasicFormData?.dateofbirth && new Date(getBasicFormData?.dateofbirth)
						? new Date(getBasicFormData?.dateofbirth)
						: get18YearsAgo(),
				permanent_address: stringValueCheck(getBasicFormData.permanent_address1_c),
				permanent_street: stringValueCheck(getBasicFormData.permanentstreet_c),
				permanent_zipcode: numberValueCheck(getBasicFormData.permanentzipcode_c),
				permanent_state: stringValueCheck(getBasicFormData.permanentstate_c),
				permanent_city: stringValueCheck(getBasicFormData.permanentcity_c),
				permanent_town: `${getBasicFormData.town_code_c} - ${getBasicFormData.town_name_c}`,
				local_address: stringValueCheck(getBasicFormData.permanent_address2_c),
				local_city: stringValueCheck(getBasicFormData.local_city_c),
				local_state: stringValueCheck(getBasicFormData.local_state_c),
				local_street: stringValueCheck(getBasicFormData.local_street_c),
				local_zipcode: stringValueCheck(getBasicFormData.local_pin_code_c?.toString()),
				work_address: stringValueCheck(getBasicFormData.permanent_address3_c),
				work_city: stringValueCheck(getBasicFormData.work_city_c),
				work_street: stringValueCheck(getBasicFormData.work_street_c),
				work_zipcode: numberValueCheck(getBasicFormData.work_pin_code_c),
				work_state: stringValueCheck(getBasicFormData.work_state_c),
				religion: stringValueCheck(getBasicFormData.religion),
				primary_number: stringValueCheck(getBasicFormData.phone),
				secondary_number: stringValueCheck(getBasicFormData.AlternateMobileNo1),
				language: stringValueCheck(getBasicFormData.language),
				sites_worked: stringValueCheck(getBasicFormData.average_number_of_sites_in_a_year_c?.toString()),
				sites_competition: stringValueCheck(getBasicFormData.total_year_of_experience_c?.toString()),
				wedding_anniversary: getBasicFormData.weddinganniversary_c,
				email: stringValueCheck(getBasicFormData.email),
				samaj: stringValueCheck(getBasicFormData.samaj),
				local_address_chekbox: isLocalAddressEqual,
				work_address_chekbox: isWorkAddressEqual,
				unmarried_chekbox: !getBasicFormData.weddinganniversary_c,
				samaj_checkbox: !getBasicFormData.samaj,
				competitionConsumption: Number(getBasicFormData?.competitionconsumption_c),
				avg_monthly_banking_six_months: getBasicFormData?.avg_monthly_banking_six_months ?? 0,
			};
			formik.setValues(updatedValue);
			if (isFormEdit) {
				formik.validateForm(updatedValue).then((errors) => {
					Object.keys(errors).forEach((fieldName) => {
						formik.setFieldTouched(fieldName, true, true);
					});
				});
			}
		}
		// eslint-disable-next-line
	}, [getBasicFormData, formId, townFetching, isFormEdit]);

	useEffect(() => {
		if (!basicDetailsRevalidate) {
			setEndUserKeyBaseValue('upperFormRevalidateFiled', {
				average_glue: true,
				competitionConsumption: true,
			});
		}
		return (): void => {
			setEndUserKeyBaseValue('upperFormRevalidateFiled', {
				average_glue: false,
				competitionConsumption: false,
			});
		};
	}, [basicDetailsRevalidate, setEndUserKeyBaseValue]);

	return (
		<>
			<div className="all-user-details-form">
				<Wrapper isLoaderVisible={isGetBasicFormFetching} isError={isGetBasicFormError}>
					<form onSubmit={formik.handleSubmit}>
						<ul className="user-details-wrapper row">
							<UpperFormSection {...commonFormProps} />
							<MiddleFormSection {...commonFormProps} townsList={townsList} />
							<BottomFormSection {...commonFormProps} />
						</ul>

						<SubmitButton
							isEditing={isEditing}
							isPending={isBasicFormPending}
							isDisabled={!formik.isValid || !formik.dirty || isBasicFormPending || isPhoneDetailsFetching}
							setIsEditing={setIsEditing}
							handleSubmitClick={handleSubmitClick}
						/>
					</form>
				</Wrapper>
			</div>
			<Modal
				modalOpen={isOpen}
				modalId={'closeLeads'}
				onModalClose={() => {
					setIsOpen(false);
				}}
				className="dialog-sm close-site-popup dialog-conform"
			>
				<SixMonthsConfirmationModal
					message={'Are you sure you have verified all the highlighted information?'}
					modalClose={() => setIsOpen(false)}
					handleConfirm={() => formik.handleSubmit()}
				/>
			</Modal>
		</>
	);
};
