import { useRxData } from 'rxdb-hooks';
import { MangoQuery, RxCollection, RxDocument } from 'rxdb/dist/types/types';
import { useCallback } from 'react';
import { useRxCountQuery } from '@hooks/useCount';
import { IFactoryVisitDetail } from '@components/factoryList';

export type IFactoryVisitReturn = {
	result: RxDocument<IFactoryVisitDetail>[];
	isFetching: boolean;
	fetchMore: () => void;
	totalCount: number;
};

export interface IFactoryVisitListFilterProps {
	searchValue?: string;
	sortBy?: string;
	visitDate?: [null | Date, null | Date];
	competition_site?: boolean;
}

export const useFactoryVisitRxDb = (props: IFactoryVisitListFilterProps): IFactoryVisitReturn => {
	const { searchValue = '', sortBy, visitDate, competition_site } = props;

	const queryConstructor = useCallback(
		(collection: RxCollection<IFactoryVisitDetail>) => {
			let querySelector: MangoQuery<IFactoryVisitDetail> = {
				selector: {
					$or: [
						{
							factory_name: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							id: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{ contractor_phone: { $regex: `.*${searchValue}.*`, $options: 'i' } },
						{ contractor_name: { $regex: `.*${searchValue}.*`, $options: 'i' } },
						{
							membership_number_c: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
					],
				},
				sort: [{ activitydatetime: 'desc' }],
			};

			if (querySelector.selector && competition_site) {
				querySelector.selector.competition_brand_at_factory = competition_site;
			}

			if (querySelector.selector && visitDate) {
				const [startDate, endDate] = visitDate;
				if (startDate && endDate) {
					querySelector.selector.activitydatetime = {
						$gte: startDate.toISOString(),
						$lte: endDate.toISOString(),
					};
				}
			}

			if (sortBy) {
				querySelector = {
					...querySelector,
					sort: [{ [sortBy]: 'desc' }],
				};
			}

			return collection.find(querySelector);
		},
		[competition_site, visitDate, searchValue, sortBy]
	);
	const totalCount = useRxCountQuery<IFactoryVisitDetail>('factory_visit_list', queryConstructor);

	const { result, isFetching, fetchMore } = useRxData<IFactoryVisitDetail>('factory_visit_list', queryConstructor, {
		pageSize: 20,
		pagination: 'Infinite',
	});

	return {
		result,
		isFetching,
		fetchMore,
		totalCount,
	};
};
