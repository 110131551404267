import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { ISupervisorBankingHistoryDetail } from '@components/contractor360/components/supervisorBankingHistory';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { setRxdb } from '@helper/utils';
import { ErrorHandler } from '@helper/errorHandler';

export const useGetSupervisorBankingDetails = (
	membership_no: string,
	phone: string,
	forceFetch = false
): IQueryResponse<ISupervisorBankingHistoryDetail[]> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('supervisor_banking_history');
	const fetchSupervisorBankingDetail = async (): Promise<ISupervisorBankingHistoryDetail[]> => {
		const url = `${APICONFIG.SUPERVISOR_BANKING_HISTORY}`;
		try {
			const response = await APIService.getData<ISupervisorBankingHistoryDetail[]>(url, {
				limit: '0',
				membership_no: membership_no,
				phone: phone,
			});
			if (response) {
				const data = response.map((item) => {
					return {
						...item,
						id: item?.id?.toString(),
					};
				});
				await setRxdb('supervisor_banking_history', data);
			}
			return response;
		} catch (error) {
			ErrorHandler(error);
			return [];
		}
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['fetchSupervisorBankingDetail', membership_no],
		queryFn: fetchSupervisorBankingDetail,
		enabled: (isDbChecked && shouldFetch) || forceFetch,
		refetchOnWindowFocus: false,
		retry: false,
		gcTime: 0,
		staleTime: 0,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
