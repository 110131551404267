import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import Checkbox from '@components/common/formControl/checkbox/checkbox';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { useGetMemberByFilterOptions } from '@services/hooks/endusers';
import { useInfiniteScroll } from '@components/common';
import { useEndUserStore } from '@store/enduser';
import { useLocation } from 'react-router-dom';
import { useGetContractorDetails } from '@services/hooks/enduser-details/useGetContractorDetailsQuery';

export const SubContractorMapping = (): ReactElement => {
	const { state } = useLocation();
	const [memberFilterOption, setMemberFilterOption] = useState<string>('');
	const [selectedSubContractor, setSelectSubContractor] = useState<string[]>([]);
	const { selectedSubContractor: selectedStoreValue, setSelectSubContractor: setStoreValue } = useEndUserStore();
	const ulList = useRef<HTMLUListElement | null>(null);
	const {
		result: memberList,
		fetchMore: memberFetchMore,
		isFetching,
	} = useGetMemberByFilterOptions({
		searchValue: memberFilterOption,
		contractorclass: [],
		subcontractor: true,
		parent_member_id_c: state?.subContractorMemberShipNo,
	});

	const { data: contractorsDetail, isFetching: loading } = useGetContractorDetails(state.subContractorMemberShipNo);

	useEffect(() => {
		if (!state.isFormEdit) return;
		if (contractorsDetail && contractorsDetail.data && !loading) {
			const memberShipNos: string[] = contractorsDetail.data.map((member) => member.membershipno as string) ?? [];
			setSelectSubContractor?.(memberShipNos);
		}
	}, [contractorsDetail, state?.isFormEdit, setSelectSubContractor, loading]);

	useEffect(() => {
		const hideElement = document.querySelector('.slide-tab-wrapper');
		if (hideElement) {
			hideElement.classList.add('hide-tab');
		}

		return (): void => {
			const hideElement = document.querySelector('.slide-tab-wrapper');
			hideElement?.classList.remove('hide-tab');
		};
	}, []);

	useEffect(() => {
		if (selectedStoreValue) {
			setSelectSubContractor(selectedStoreValue);
		}
	}, [selectedStoreValue]);

	const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault();
		setMemberFilterOption(e.target.value ?? '');
	}, []);

	const handleSelectAll = useCallback((): void => {
		if (memberList && memberList.length !== 0) {
			setSelectSubContractor((prevUsers) => {
				if (prevUsers.length === memberList.length) {
					return [];
				}
				return memberList.map((item) => item?.membershipno);
			});
		}
	}, [memberList]);

	const isSubContractorSelected = useCallback(
		(userId: string): boolean => {
			return selectedSubContractor.some((user) => user === userId);
		},
		[selectedSubContractor]
	);

	const handleCheckBoxChange = useCallback((item: string): void => {
		setSelectSubContractor((prevUsers) => {
			const existingUserIndex = prevUsers.findIndex((user) => user === item);
			if (existingUserIndex !== -1) {
				return prevUsers.filter((_, index) => index !== existingUserIndex);
			}
			return [...prevUsers, item];
		});
	}, []);

	const handleSubmitHandler = (): void => {
		const backBtn = document.getElementById('backButton') as HTMLButtonElement;
		if (backBtn) {
			backBtn.click();
		}
		setStoreValue && setStoreValue(selectedSubContractor);
	};

	useInfiniteScroll(() => {
		memberFetchMore?.();
	}, ulList);

	return (
		<div className="manage-participants">
			<div className="container">
				<div className="filter-section">
					<div className="search-filter-wrapper">
						<div className="search-with-filter">
							<div className="search">
								<form>
									<div className="search-row">
										<div className="input-col">
											<label htmlFor="search-top" className="hide">
												search
											</label>
											<input
												type="text"
												name="search"
												id="search-top"
												placeholder="Search by Name / Mobile Number"
												className="search-bar"
												autoComplete="off"
												value={memberFilterOption}
												onChange={handleSearch}
											/>
										</div>
										<button className="search-button" aria-label="search user" disabled>
											<span className="icon-search"></span>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<p className="text-center participants-number">
					Total number of participants selected: {selectedSubContractor.length}
				</p>

				<Wrapper isLoaderVisible={isFetching} isError={null}>
					<div className="participants-list">
						<div className="all-select">
							<Checkbox
								id="allSelect"
								name="allSelect"
								type="checkbox"
								checked={
									selectedSubContractor &&
									selectedSubContractor.length > 0 &&
									selectedSubContractor.length === memberList?.length
								}
								label="Select All"
								disabled={isFetching}
								onChange={handleSelectAll}
							/>
						</div>

						<ul className="participants-box" ref={ulList}>
							{Array.isArray(memberList) && memberList?.length > 0 ? (
								memberList?.map((item) => (
									<li key={item.id}>
										<div className="participants-list-wrapper">
											<Checkbox
												name={item.membershipno}
												type="checkbox"
												id={item.membershipno}
												checked={isSubContractorSelected(item.membershipno)}
												label={item.name}
												disabled={isFetching}
												onChange={() => handleCheckBoxChange(item.membershipno)}
											/>

											<div className="mobile-number">
												<a href={`tel:+91${item.phone}`} aria-label="mobile number">
													<span className="icon-call"></span>
													<span className="user-number">{item.phone}</span>
												</a>
											</div>
										</div>
									</li>
								))
							) : (
								<NoDataFound />
							)}
						</ul>
					</div>
				</Wrapper>
				<div className="submit-btn">
					<button
						className="btn btn-medium btn-primary"
						id="next-page"
						onClick={handleSubmitHandler}
						disabled={selectedSubContractor.length === 0}
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	);
};
