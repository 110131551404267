export interface IDashboardPowerBi {
	id?: string;
}

export interface IScoreCardLinks {
	role: string;
	link: string;
}

export const scoreCardLinks: IScoreCardLinks[] = [
	{
		role: 'IMR',
		link: 'https://app.powerbi.com/groups/me/apps/70b919b4-f14f-471a-ac53-1b9e18562a82/reports/aab22f0d-bb42-4e6c-a784-8242bada5b49/ReportSectiona33c6c3065bdc4dd9235?experience=power-bi',
	},
	{
		role: 'BDE',
		link: 'https://app.powerbi.com/groups/me/apps/70b919b4-f14f-471a-ac53-1b9e18562a82/reports/293dcf7c-192b-4ed5-81b7-6969fc81cf54/ReportSectiona33c6c3065bdc4dd9235?experience=power-bi',
	},
	{
		role: 'TSI',
		link: 'https://app.powerbi.com/groups/me/apps/70b919b4-f14f-471a-ac53-1b9e18562a82/reports/293dcf7c-192b-4ed5-81b7-6969fc81cf54/ReportSectiona33c6c3065bdc4dd9235?experience=power-bi',
	},
	{
		role: 'KAE',
		link: 'https://app.powerbi.com/groups/me/apps/70b919b4-f14f-471a-ac53-1b9e18562a82/reports/293dcf7c-192b-4ed5-81b7-6969fc81cf54/ReportSectiona33c6c3065bdc4dd9235?experience=power-bi',
	},
];
