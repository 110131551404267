import { ReactElement, useCallback } from 'react';
import { IUserDetails } from '.';
import { NavLink, useLocation } from 'react-router-dom';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { getRelativeDate, renderNullCase } from '@helper/utils';
import { useEndUserStore } from '@store/enduser';
import { useStoreFormikFormValue } from '@store/setFormikFormValueStore';

export const UserDetails = (props: IUserDetails): ReactElement => {
	const { data, isGetContractorDetailFetching, error } = props;
	const { setIsEditing, setActiveNavTab, setIsFormEdit, setFormId, setFormRevalidate } = useEndUserStore();
	const { pathname } = useLocation();
	const stateData = {
		id: data?.id,
		status: 'Basic Details',
		activeFormTabs: [0, 1, 2],
		isEdit: true,
		membershipNumber: data?.membershipno,
		prevPath: pathname,
	};
	const { resetFormikValuesToStore } = useStoreFormikFormValue();

	const handleClick = useCallback((): void => {
		if (data?.id) {
			setIsEditing(true);
			setActiveNavTab([0, 1, 2]);
			setFormId(data.id);
			setIsFormEdit(true);
			setFormRevalidate(false);
			resetFormikValuesToStore();
		}
	}, [data?.id, setIsEditing, setActiveNavTab, setFormId, setIsFormEdit, setFormRevalidate, resetFormikValuesToStore]);

	return (
		<section className="user-detail-wrapper">
			<Wrapper isLoaderVisible={isGetContractorDetailFetching} isError={error}>
				<div className="container">
					<div className="user-detail-inner">
						<div className="user-title">
							<h1 className="h3">{data?.name}</h1>
							<span className="all-detail">
								<NavLink
									to={`/edit-member-details/basic-details`}
									state={stateData}
									onClick={handleClick}
									aria-label="All Details"
								>
									All Details
								</NavLink>
								<span className="icon-right-arrow"></span>
							</span>
						</div>
						<div className="membership-bag">{data?.membershipno} </div>

						<ul className="row">
							<li className="d-col d-col-6">
								<span>
									<span className="h5"> Member Class</span>
									{data?.contractorclass !== null ? (
										<span className="h3">{data?.contractorclass}</span>
									) : (
										renderNullCase('h3 just-center')
									)}
								</span>
							</li>
							<li className="d-col d-col-6">
								<span>
									<span className="h5">pwg (kg Qtd)</span>
									{data?.pwg_banking_qtd !== null ? (
										<span className="h3">{data?.pwg_banking_qtd}</span>
									) : (
										renderNullCase('h3 just-center')
									)}
								</span>
							</li>
							<li className="d-col d-col-6">
								<span>
									<span className="h5">Glue Potential</span>
									{data?.glue_consumption_per_month !== null ? (
										<span className="h3">
											{Number.isInteger(data?.glue_consumption_per_month)
												? data?.glue_consumption_per_month
												: data?.glue_consumption_per_month?.toFixed(2)}{' '}
											KG
											<NavLink
												to={`/edit-member-details/basic-details`}
												state={stateData}
												onClick={handleClick}
												aria-label="All Details"
											>
												{' '}
												<span className="icon-edit"></span>
											</NavLink>
										</span>
									) : (
										renderNullCase('h3 just-center')
									)}

									<span className="h5 smalltext">(Last edited {getRelativeDate(data?.lastmodifieddate)})</span>
								</span>
							</li>
							<li className="d-col d-col-6">
								<span>
									<span className="h5">Competition usage</span>
									{data?.competitionconsumption !== null ? (
										<span className="h3">{data?.competitionconsumption} KG</span>
									) : (
										renderNullCase('h3 just-center')
									)}
								</span>
							</li>
						</ul>
					</div>
				</div>
			</Wrapper>
		</section>
	);
};
