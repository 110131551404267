import { useMemo } from 'react';
import { usePermission } from './usePermission';
import { useGetRxDb } from '@hooks/getRxdbData';
import { ProfileData } from '@services/hooks/profile';

export const useComponentLevelAccessCheck = (
	componentName: string,
	routeLevelCheck = false,
	salesGroupCheck = false
): boolean => {
	const permissions = usePermission();
	const { result: profileData } = useGetRxDb<ProfileData>('profile');

	return useMemo(() => {
		if (!permissions || permissions.length === 0) {
			return false;
		}

		if (routeLevelCheck) {
			return permissions.some((permission) => permission?.route?.includes(componentName));
		}
		if (salesGroupCheck) {
			return !permissions.some((permission) => permission.components.includes(profileData[0]?.salesgroupid_c));
		}

		if (permissions.some((permission) => permission.components.includes(componentName))) {
			return false;
		}

		return permissions.some((permission) => !permission.components.length);
	}, [permissions, routeLevelCheck, salesGroupCheck, componentName, profileData]);
};
