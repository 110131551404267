export interface IDashboardQuarterSummary {
	id?: string;
}

export interface ITargetValues {
	meetingTarget: number;
	userTarget: number;
	siteVisitTarget: number;
}

export const TotalSiteCount = {
	IMR: 300,
	BDE: 255,
};

export * from './summaryCard';
export * from './quarterSummary';
export * from './bankingDetailsCard';
