import { RxCollectionCreator } from 'rxdb';

export const SupervisorBankingHistorySchema: RxCollectionCreator = {
	schema: {
		title: 'Supervisor Banking History List',
		description: 'Supervisor Banking History Schema',
		version: 0,
		primaryKey: 'id',
		type: 'object',
		properties: {
			id: { type: 'string', maxLength: 250 },
			barcode_no: { type: 'string' },
			billing_time: { type: ['string', 'null'] },
			code_of_value: { type: ['string', 'null'] },
			contractor_membership_no: { type: ['string', 'null'] },
			delivery_status: { type: ['string', 'null'] },
			item_code: { type: ['string', 'null'] },
			item_name: { type: ['string', 'null'] },
			membership_no: { type: ['string', 'null'] },
			points: { type: ['string', 'null'] },
			source_of_code: { type: ['string', 'null'] },
			status: { type: ['string'] },
			supervisor_name: { type: ['string'] },
			supervisor_phone: { type: ['string', 'null'] },
			units: { type: ['string', 'null'] },
			weights: { type: ['string', 'null'] },
		},
		required: ['id'],
	},
};
