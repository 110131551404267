import { create } from 'zustand';
import { getDaysBetweenDates } from '@helper/dateFormat';

interface IConformationProps {
	end_user_profiling_freq: number;
	basic_details_updated: Date;
	more_details_updated: Date;
}

interface IUpperFormRevalidateFiled {
	average_glue: boolean;
	competitionConsumption: boolean;
}

export interface IMoreDetailsRevalidateField {
	primary_details: boolean[];
	competition_brand: boolean[];
	competition_volume: boolean[];
	workshop_address: boolean;
	workshop_zipcode: boolean;
	workshop_city: boolean;
	workshop_street: boolean;
	workshop_state: boolean;
	architect_name: boolean[];
	architect_number: boolean[];
	factory_address: boolean;
	factory_city: boolean;
	factory_state: boolean;
	factory_zipcode: boolean;
}

interface IEndUserProfileStore {
	isConformationNeeded: boolean;
	openConformationModal: boolean;
	basicDetailsRevalidate: boolean;
	moreDetailsRevalidate: boolean;
	upperFormRevalidateFiled: IUpperFormRevalidateFiled;
	moreDetailFormRevalidateField: IMoreDetailsRevalidateField;
	setKeyBaseValue: <T>(key: string, value: T) => void;
	setSubKeyValue: (key: string, subKey: string, value: boolean | boolean[]) => void;
	setSubKeyBaseValue: (key: string, subKey: string, index: number, value: boolean) => void;
	setConformationNeeded: (props: IConformationProps) => void;
}

export const initialMoreDetailsValidateField = {
	primary_details: [true],
	competition_brand: [true],
	competition_volume: [true],
	workshop_address: true,
	workshop_zipcode: true,
	workshop_city: true,
	workshop_street: true,
	workshop_state: true,
	architect_name: [true],
	architect_number: [true],
	factory_address: true,
	factory_city: true,
	factory_state: true,
	factory_zipcode: true,
};

const initialState = {
	openConformationModal: false,
	isConformationNeeded: false,
	basicDetailsRevalidate: false,
	moreDetailsRevalidate: false,
	upperFormRevalidateFiled: {
		average_glue: false,
		competitionConsumption: false,
	},
	moreDetailFormRevalidateField: initialMoreDetailsValidateField,
};

export const useEndUserProfileStore = create<IEndUserProfileStore>((set) => ({
	...initialState,
	setConformationNeeded: (props: IConformationProps): void =>
		set((state) => {
			const { end_user_profiling_freq, more_details_updated, basic_details_updated } = props;
			const lastBasicUpdateDays = getDaysBetweenDates(basic_details_updated);
			const lastMoreDetailsUpdateDays = getDaysBetweenDates(more_details_updated);
			if (lastBasicUpdateDays > end_user_profiling_freq || lastMoreDetailsUpdateDays > end_user_profiling_freq) {
				return {
					...state,
					isConformationNeeded: true,
				};
			}
			return {
				...state,
				isConformationNeeded: false,
			};
		}),
	setKeyBaseValue: <T,>(key: string, value: T): void =>
		set((state) => ({
			...state,
			[key]: value,
		})),
	setSubKeyValue: (key: string, subKey: string, value: boolean | boolean[]): void =>
		set((state) => ({
			...state,
			[key]: {
				...state[key],
				[subKey]: value,
			},
		})),
	setSubKeyBaseValue: (key: string, subKey: string, index: number, value: boolean): void =>
		set((state) => ({
			...state,
			[key]: {
				...state[key],
				[subKey]: [...state[key][subKey].slice(0, index), value, ...state[key][subKey].slice(index + 1)],
			},
		})),
}));
