import { ReactElement } from 'react';
import { BankingTertiaryCard } from './bankingTertiaryDetailCard';
import { NavLink, useLocation } from 'react-router-dom';
import { IBankingTertiaryDetails } from '.';
import { SUPERVISOR_ALLOWED_CLASS } from '@config/constant';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';

export const BankingTertiaryDetails = (props: IBankingTertiaryDetails): ReactElement => {
	const { bankingDetails } = props;
	const { state } = useLocation();
	const supervisorAccess = useComponentLevelAccessCheck('/supervisor', true);

	return (
		<section className="tertiary-section">
			<div className="container">
				<ul className="tertiary-details-card-list">
					<BankingTertiaryCard label="Tertiary Current Month" value={bankingDetails?.data?.tertiary_current_month} />
					<BankingTertiaryCard
						label="Tertiary Current Quarter"
						value={bankingDetails?.data?.tertiary_current_quarter}
					/>
				</ul>
				<section className="list-components-wrapper list-components-wrapper-supervisor">
					<div className="container">
						<ul className="banking-history-card">
							<li>
								<NavLink
									to={'/contractor/banking-history'}
									state={{
										number: state.number,
									}}
									aria-label="product banked tab"
									className="banking-history-card-wrapper"
								>
									<div className="left-content">
										<span className="icon-file" />
										<h2 className="h5">Banking History</h2>
									</div>

									<span className="icon-arrow-right-circle" />
								</NavLink>
							</li>
							{SUPERVISOR_ALLOWED_CLASS.includes(state?.contractorClass ?? '') && supervisorAccess && (
								<li>
									<NavLink
										to="/contractor/supervisor-banking-history"
										aria-label="supervisor summary"
										state={{
											membershipNumber: state.membershipNumber,
											number: state.number,
										}}
										className="banking-history-card-wrapper"
									>
										<div className="left-content">
											<span className="icon-user"></span>
											<h2 className="h5">Supervisor Banking History</h2>
										</div>
										<span className="icon-arrow-right-circle"></span>
									</NavLink>
								</li>
							)}
						</ul>
					</div>
				</section>
			</div>
		</section>
	);
};
