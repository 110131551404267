import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { ContractorStatus } from '@components/contractor360/components/contractorSites';

interface ProductWeight {
	membershipNo: string;
}

interface IRecommendedProductsResponse {
	data?: ContractorStatus;
	mutate: UseMutateFunction<ContractorStatus, Error, ProductWeight, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useContractorStatusQuery = (): IRecommendedProductsResponse => {
	const fetchRecommendedProducts = async (params: ProductWeight): Promise<ContractorStatus> => {
		return APIService.getData<ContractorStatus>(`${APICONFIG.GET_KAE_STATUS}?membership_no=${params.membershipNo}`);
	};

	const { mutate, error, isError, isPending, data } = useMutation({
		mutationFn: fetchRecommendedProducts,
	});

	return {
		data,
		mutate,
		error,
		isError,
		isPending,
	};
};
