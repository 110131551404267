import { forwardRef, ReactElement } from 'react';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { BankingHistoryObject } from '.';

export const BankingData = forwardRef<
	HTMLTableSectionElement,
	{ bankingHistoryData: BankingHistoryObject[]; cardData: string; isSupervisor?: boolean }
>((props, ref): ReactElement => {
	const { bankingHistoryData, cardData, isSupervisor = false } = props;

	return (
		<>
			<div className="details-history-card">
				<ul className="history-card-list">
					{bankingHistoryData?.length > 0 ? (
						bankingHistoryData?.map((item, index) => (
							<li key={item?.couponCode + '_' + index}>
								<div className="history-card">
									<div className="left-content">
										<div className="top-content">
											<p className="coupen-code">
												<span className="code">{item.couponCode} </span>
												<span className="count">{item?.kg} Kgs </span>
											</p>
											<span className="product-name h5">{item?.itemName}</span>
										</div>
									</div>
									<div className="right-content">
										<div className="top-content">
											<span className="date">{item?.codeUsedOn}</span>
											<span className="coupen-id">{item?.productCode}</span>
										</div>
									</div>
								</div>
								<div className={'history-card ' + `${cardData !== 'banking' ? 'bottom-align' : 'normal-box'}`}>
									<div className="left-content">
										<p className="source-code h5">
											{cardData === 'banking' ? 'Source of code:' : 'Remarks:'} {item?.sourceOfCode}
										</p>
										{isSupervisor && (
											<>
												<p className="source-code h5">Supervisor Name {item?.supervisor_name}</p>
												<p className="source-code h5">Supervisor Number {item?.supervisor_phone}</p>
											</>
										)}
									</div>
									<div className="right-content">
										<div className="code-value h5">
											{cardData === 'banking' ? 'Code Value' : 'Points'} <span className="h3">{item?.codeValue}</span>
											{cardData !== 'banking' && (
												<>
													Bonus Points <span className="h3">{item?.bonusPoint}</span>
												</>
											)}
										</div>
									</div>
								</div>
							</li>
						))
					) : (
						<NoDataFound message="No Records Found" />
					)}
				</ul>
			</div>

			{typeof window !== 'undefined' && window.innerWidth >= 1280 && (
				<div className="details-history-card-table" ref={ref}>
					<table className="history-table">
						<thead>
							<tr>
								<th>Sl No.</th>
								<th>Coupon code</th>
								{cardData === 'banking' ? (
									<>
										<th>Product code</th>
										<th>Code value</th>
										<th>Code Used On</th>
										<th>Item Name</th>
										<th>Source of code</th>
										<th>Kg</th>
										{isSupervisor && (
											<>
												<th>Supervisor Name</th>
												<th>Supervisor Number</th>
											</>
										)}
									</>
								) : (
									<>
										<th>Date</th>
										<th>Item Description</th>
										<th>Point</th>
										<th>Bonus Point</th>
										<th>Kgs</th>
										<th>Sku</th>
										<th>Remark</th>
									</>
								)}
							</tr>
						</thead>
						<tbody>
							{bankingHistoryData?.length > 0 ? (
								bankingHistoryData.map((item, index) => (
									<tr key={item?.couponCode + index}>
										<td>{index + 1}</td>
										<td>{item.couponCode}</td>
										{cardData === 'banking' ? (
											<>
												<td>{item.productCode}</td>
												<td>{item.codeValue}</td>
												<td>{item.codeUsedOn}</td>
												<td>{item.itemName}</td>
												<td>{item.sourceOfCode}</td>
												<td>{item.kg}</td>
												{isSupervisor && (
													<>
														<td>{item?.supervisor_name}</td>
														<td>{item?.supervisor_phone}</td>
													</>
												)}
											</>
										) : (
											<>
												<td>{item.codeUsedOn}</td>
												<td>{item.itemName}</td>
												<td className="text-center">{item.codeValue}</td>
												<td className="text-center">{item.bonusPoint}</td>
												<td className="text-center">{item.kg}</td>
												<td>{item.productCode}</td>
												<td>{item.sourceOfCode}</td>
											</>
										)}
									</tr>
								))
							) : (
								<tr>
									<td colSpan={8}>
										<p className="h3 text-center">No Records Found</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
});

BankingData.displayName = 'BankingData';
