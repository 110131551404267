import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRedemptionHistoryQuery } from '@services/hooks/contractor360/useRedemptionHistoryQuery';
import { formatDateDDMMYY } from '@helper/utils';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useInfiniteScroll } from '@components/common';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import '@assets/styles/pages/banking-history.scss';
import { BankingData } from '../bankingHistory/bankingCard';
import { BankingHistoryObject, FilterObject } from '../bankingHistory';
import { BankingHistoryFilter } from '../bankingHistory/bankingHistoryFilter';

export const PointsHistory = (): ReactElement => {
	const [pointHistoryData, setPointHistoryData] = useState<BankingHistoryObject[]>([]);
	const [date, setDate] = useState<Value>([null, null]);
	const { state } = useLocation();
	const [filter, setFilter] = useState<FilterObject>({
		limit: 50,
		offset: 0,
		search: '',
	});
	const pointRef = useRef(null);

	const startDate = useMemo(() => (Array.isArray(date) ? date[0] : null), [date]);
	const endDate = useMemo(() => (Array.isArray(date) ? date[1] : null), [date]);

	const { data, isFetching, error } = useRedemptionHistoryQuery({
		startDate: startDate,
		endDate: endDate,
		limit: 50,
		mobile: state?.number,
		search: filter.search,
		offset: (filter.offset + 1) * 50 - 50,
	});

	useInfiniteScroll(() => {
		if (data && filter.limit < +data?.response?.customer?.count) {
			setFilter((prevFilter) => ({
				...prevFilter,
				limit: prevFilter.limit + 50,
				offset: prevFilter.offset + 1,
			}));
		}
	}, pointRef);

	const handleSearch = (value: string): void => {
		setFilter({
			limit: 50,
			offset: 0,
			search: value,
		});
		setPointHistoryData([]);
	};

	const handleDateChange = (date: Value): void => {
		setDate(date);
		setPointHistoryData([]);
	};

	const ProcessPointHistoryData = (): void => {
		const tempPointData: BankingHistoryObject[] =
			data?.response?.customer?.transactions?.transaction?.flatMap((transaction) => {
				const customFields = new Map(transaction.custom_fields.field.map((field) => [field.name, field.value]));
				const sourceFound =
					customFields.has('source') ||
					transaction?.points?.issued?.toString() !== '0' ||
					transaction?.extended_fields?.field.find((ele) => ele?.name?.toLowerCase() === 'parent_bill_no');

				const remark = customFields.get('source');
				const kg = customFields.get('gross_weight') ?? '-';

				const itemBonusPoint =
					transaction?.points?.points_breakup?.current_points?.promotional_points_breakup?.[0]?.issued ?? '-';

				const itemPoint =
					transaction?.points?.points_breakup?.current_points?.regular_points_breakup?.[0]?.issued ?? '-';

				if (!sourceFound) return [];

				const date = formatDateDDMMYY(new Date(transaction.billing_time));

				if (transaction?.line_items?.line_item?.length === 0) {
					return {
						couponCode: transaction?.number,
						productCode: '-',
						codeValue: itemPoint,
						codeUsedOn: date,
						itemName: '-',
						bonusPoint: itemBonusPoint,
						sourceOfCode: remark ?? '-',
						kg,
					};
				}

				return transaction?.line_items?.line_item?.map((item) => {
					const itemDescription =
						(item.description ||
							item?.attributes?.attribute?.find((attr) => attr.name === 'ProductDescription')?.value) ??
						'-';

					return {
						couponCode: transaction?.number,
						productCode: item?.item_code,
						codeValue: itemPoint,
						codeUsedOn: date,
						itemName: itemDescription,
						bonusPoint: itemBonusPoint,
						sourceOfCode: remark ?? '-',
						kg,
					};
				});
			}) ?? [];

		setPointHistoryData((prevData) => [...prevData, ...tempPointData]);
	};

	useEffect(() => {
		ProcessPointHistoryData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.response?.customer?.transactions?.transaction]);

	return (
		<section className="banking-history">
			<BankingHistoryFilter
				setSearch={handleSearch}
				date={date}
				setDate={handleDateChange}
				records={{
					length: pointHistoryData.length,
					count: data?.response?.customer?.count ?? pointHistoryData.length,
				}}
			/>
			<div className="container">
				<Wrapper isLoaderVisible={isFetching && pointHistoryData.length == 0} isError={error}>
					<BankingData bankingHistoryData={pointHistoryData} ref={pointRef} cardData="point" />
				</Wrapper>
				<Wrapper isLoaderVisible={isFetching && pointHistoryData.length !== 0} isError={null}>
					<div></div>
				</Wrapper>
			</div>
		</section>
	);
};
