import { Wrapper } from '@components/common/Wrapper/wrapper';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import '@assets/styles/pages/addEditPcmsRegistration.scss';
import { useFormik } from 'formik';
import { GoogleMapModal, SubmitButton } from '@components/common';
import { pcmsInitialData, PcmsObject } from '.';
import { DealerWhoRaisedAComplaintForm } from './dealerWhoRaisedaComplaint';
import { ProductDetailsForm } from './productDetailsForm';
import { PcmsContentCard } from './pcmsContentCard';
import { ComplaintDetailsForm } from './complaintDetails';
import { CourierDetails } from './courierDetails';
import { useValidation } from '@hooks/validations';
import { usePcmsMutation } from '@services/hooks/pcms-registration/addEditPcmsMutation';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastType, useTosterStore } from '@store/toster';
import { usePcmsListQuery } from '@services/hooks/pcms-registration/useGetPcmsRegistrationList';
import { useGetPcmsQuery } from '@services/hooks/pcms-registration/useGetPcmsRegistraionForm';
import { SiteVisitForm } from './siteVisitForm';
import { PresignedURLDetails } from '@services/hooks/enduser-details';
import { useUploadSiteImagesMutation } from '@services/hooks/site-leads/useUploadSiteImagesMutation';
import { extractLink, normalizeDate } from '@helper/utils';
import { convertUrlToFile } from '@components/delaerVisit/components/addEditDealerVisit';
import { useGoogleMapStore } from '@store/googleMapStore';
import { Modal } from '@components/common/modal/modal';
import { useGeoLocationDetails } from '@hooks/useGeoLocationDetails';
import { ConfirmationModal } from '@components/conductedMeetings/confirmationModal/confirmationModal';
import { useSetMainHeader } from '@hooks/useSetMainHeader';

export const AddEditPcmsRegistrationForm = (): ReactElement => {
	useSetMainHeader('PCMS');
	const { PcmsRegistrationSchema } = useValidation();
	const { state } = useLocation();
	const { mutate, isPending } = usePcmsMutation(state?.id);
	const { data, isFetching } = useGetPcmsQuery(state?.id);
	const { currentAddress } = useGoogleMapStore();
	const { locationDetails } = useGeoLocationDetails();
	const navigate = useNavigate();
	const formik = useFormik({
		initialValues: pcmsInitialData,
		onSubmit: () => {
			handleSubmit();
		},
		validationSchema: state?.id ? '' : PcmsRegistrationSchema,
	});
	const { setToaster } = useTosterStore();
	const { refetch } = usePcmsListQuery();
	const baseCategories = ['Product Details', 'Dealer/User who raised the complaint', 'Complaint Details'];
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
	const categories = useMemo(() => {
		const updatedCategories = [...baseCategories];

		if (formik.values.complaint_sample_availability_c === 'Yes') {
			updatedCategories.push('Courier Details');
		}
		if (formik.values.nature_of_complaints_c === 'Product Application Complaint') {
			updatedCategories.push('Site Visit Report');
		}

		return updatedCategories;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.complaint_sample_availability_c, formik.values.nature_of_complaints_c]);

	const [openItems, setOpenItems] = useState<string[]>([]);

	const toggleAccordion = (category: string): void => {
		setOpenItems((prev) => (prev.includes(category) ? prev.filter((item) => item !== category) : [...prev, category]));
	};

	const [productImage, setProductImage] = useState<PresignedURLDetails[]>([]);

	const handleProductImageUpload = (data: PresignedURLDetails[]): void => {
		setProductImage(data);
	};

	const [courierReceipt, setCourierReceipt] = useState<PresignedURLDetails[]>([]);

	const handleCourierUpload = (data: PresignedURLDetails[]): void => {
		setCourierReceipt(data);
	};

	const [showMapModal, setShowMapModal] = useState<boolean>(false);

	const handleLocationSubmit = useCallback((): void => {
		formik.setFieldValue('address_c', currentAddress);
		setShowMapModal(false);
	}, [currentAddress, formik]);

	const handleAddressAutoFill = useCallback((): void => {
		if (locationDetails) {
			const { fullAddress } = locationDetails;
			formik.setFieldValue('address_c', fullAddress);
		}
	}, [formik, locationDetails]);

	const handleAddressSelect = (modal: boolean): void => {
		if (modal) {
			setShowMapModal((prevState) => !prevState);
			return;
		}
		handleAddressAutoFill();
	};

	const handleCloseSiteLocationModal = (): void => {
		setShowMapModal(false);
	};
	const { mutate: putProductImages, isPending: isPutSiteImagePending } = useUploadSiteImagesMutation(productImage);
	const { mutate: putCourierReceipt, isPending: isPutCourierReceiptPending } =
		useUploadSiteImagesMutation(courierReceipt);

	const handleModalConfirmation = async (): Promise<void> => {
		formik.setFieldValue('ref_lot_no_c', '');
		setShowConfirmationModal(false);
	};

	const formatManufacturedDate = (date: string | Date): string => {
		if (date instanceof Date) {
			return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
		}
		return '';
	};

	const filterFromByCategory = (category: string): ReactElement => {
		switch (category) {
			case 'Product Details':
				return (
					<ProductDetailsForm
						formik={formik}
						isEditing={state?.id ? true : false}
						onShowConfirmationPopUp={() => setShowConfirmationModal(true)}
					/>
				);
			case 'Dealer/User who raised the complaint':
				return (
					<DealerWhoRaisedAComplaintForm
						formik={formik}
						isEditing={state?.id ? true : false}
						onShowMapHandler={handleAddressSelect}
					/>
				);
			case 'Complaint Details':
				return (
					<ComplaintDetailsForm
						formik={formik}
						isEditing={state?.id ? true : false}
						onProductImageUpload={handleProductImageUpload}
					/>
				);
			case 'Courier Details':
				return (
					<CourierDetails
						formik={formik}
						isEditing={state?.id ? true : false}
						onCourierReceiptUpload={handleCourierUpload}
						onCourierSubmit={handleSubmit}
					/>
				);
			case 'Site Visit Report':
				return <SiteVisitForm formik={formik} isEditing={state?.id ? true : false} />;
			default:
				return <div></div>;
		}
	};

	const handleSubmit = useCallback(() => {
		const payload: PcmsObject = state?.id
			? {
					courier_company_ref_id: formik.values.courier_company_ref_id,
					...(formik.values.attachment_courier_receipt_c && {
						attachment_courier_receipt_c: formik.values.attachment_courier_receipt_c
							? courierReceipt?.[0]?.normal_url
							: null,
					}),
				}
			: {
					sku_names_c: formik.values.sku_names_c.split(' - ')[0],
					ref_lot_no_c: formik.values.ref_lot_no_c,
					manufactured_in_c: formik.values.manufactured_in_c
						? formatManufacturedDate(formik.values.manufactured_in_c)
						: '',
					customer_name_c: formik.values.customer_name_c,
					registered_mobile_number_c: formik.values.registered_mobile_number_c,
					wss_names_c: formik.values.wss_names_c,
					address_c: formik.values.address_c,
					nature_of_complaints_c: formik.values.nature_of_complaints_c,
					sub_category_defect_c: formik.values.sub_category_defect_c,
					description_c: formik.values.description_c,
					uom_c: formik.values.uom_c,
					pictures_videos_c: formik.values.pictures_videos_c
						? productImage?.map((item) => item.normal_url).join(',')
						: null,
					complaint_sample_availability_c: formik.values.complaint_sample_availability_c ?? '',
					sample_non_availability_reason: formik.values.sample_non_availability_reason ?? '',
					applicator_name: formik.values.applicator_name ?? '',
					stage_of_application_during_complaint_c: formik.values.stage_of_application_during_complaint_c ?? '',
					sample_non_availability_reason_others: formik.values.sample_non_availability_reason_others ?? '',
					quantity_action_c: formik.values.quantity_action_c ?? '',
					quantity_c: formik.values.quantity_c ?? '',
					application_surface_c: formik.values.application_surface_c ?? '',
					area_in_sqft_c: formik.values.area_in_sqft_c ?? '',
					observation_c: formik.values.observation_c ?? '',
					material_code_c: formik.values.material_code_c ?? '',
					batch_number_c: formik.values.batch_number_c ?? '',
					plant_code_c: formik.values.plant_code_c,
					plant_name_c: formik.values.plant_name_c,
					manufacturing_date_c: formik.values.manufacturing_date_c ?? '',
					dealer_membership_code_c: formik.values.dealer_membership_code_c,
					type_of_complaint_c: formik.values.type_of_complaint_c,
					product_code_c: formik.values.sku_names_c ? formik.values.sku_names_c.split(' - ')[1] : '',
					cluster_name_c: '',
					site_under_warranty_c: formik.values.site_under_warranty_c ?? '',
					what_is_the_warranty_certificate_number_c: formik.values.what_is_the_warranty_certificate_number_c,
					wss_codes_c: formik.values.wss_codes_c,
					courier_company_ref_id: formik.values.courier_company_ref_id,
					attachment_courier_receipt_c: formik.values.attachment_courier_receipt_c
						? courierReceipt?.[0]?.normal_url
						: null,
				};

		putProductImages(formik.values.pictures_videos_c, {
			onSuccess: () => {
				putCourierReceipt(formik.values.attachment_courier_receipt_c, {
					onSuccess: () => {
						mutate(payload, {
							onSuccess: () => {
								navigate('/pcms');
								setToaster(
									true,
									ToastType.success,
									state?.id ? 'Pcms Updated Successfully' : 'Pcms Added Successfully'
								);
								refetch?.();
							},
							onError: (error) => {
								setToaster(true, ToastType.error, error.message);
							},
						});
					},
				});
			},
		});
	}, [
		courierReceipt,
		formik.values,
		mutate,
		navigate,
		productImage,
		putCourierReceipt,
		putProductImages,
		refetch,
		setToaster,
		state?.id,
	]);

	useEffect(() => {
		if (data) {
			const convertLinksToFiles = async (links: string): Promise<(File | null)[]> => {
				const linkArray = Array.isArray(links) ? links : [links];
				if (linkArray?.length === 0) return [];
				return await Promise.all(
					linkArray.map(async (link) => {
						return convertUrlToFile(extractLink(link));
					})
				);
			};

			const convertedProfile = async (): Promise<void> => {
				if (data?.pictures_videos_c) {
					const convertedFiles = await convertLinksToFiles(data.pictures_videos_c);
					if (convertedFiles?.length > 0) {
						formik.setFieldValue('pictures_videos_c', convertedFiles);
					}
				}
			};

			const convertedCourier = async (): Promise<void> => {
				if (data?.attachment_courier_receipt_c) {
					const convertedFiles = await convertLinksToFiles(data.attachment_courier_receipt_c);
					if (convertedFiles?.length > 0) {
						formik.setFieldValue('attachment_courier_receipt_c', convertedFiles);
					}
				}
			};

			formik.setValues({
				...formik.values,
				sku_names_c: data.sku_names_c ? data.sku_names_c + ' - ' + (data.product_code_c ?? '') : '',
				ref_lot_no_c: data.ref_lot_no_c ?? '',
				manufactured_in_c: data?.manufactured_in_c ? normalizeDate(data?.manufactured_in_c) : '',
				customer_name_c: data.customer_name_c ?? '',
				registered_mobile_number_c: data.registered_mobile_number_c ?? '',
				wss_names_c: data.wss_names_c ?? '',
				address_c: data.address_c ?? '',
				nature_of_complaints_c: data.nature_of_complaints_c ?? '',
				sub_category_defect_c: data.sub_category_defect_c ?? '',
				description_c: data.description_c ?? '',
				uom_c: data.uom_c ?? '',
				complaint_sample_availability_c: data.complaint_sample_availability_c ?? '',
				sample_non_availability_reason: data.sample_non_availability_reason ?? '',
				sample_non_availability_reason_others: data.sample_non_availability_reason_others ?? '',
				quantity_action_c: data.quantity_action_c ?? '',
				quantity_c: data.quantity_c?.toString() ?? '',
				material_code_c: data.material_code_c ?? '',
				batch_number_c: data.batch_number_c ?? '',
				plant_code_c: data.plant_code_c ?? '',
				plant_name_c: data.plant_name_c ?? '',
				manufacturing_date_c: data.manufacturing_date_c ?? '',
				dealer_membership_code_c: data.dealer_membership_code_c ?? '',
				type_of_complaint_c: data.type_of_complaint_c ?? '',
				site_under_warranty_c: data.site_under_warranty_c ?? '',
				what_is_the_warranty_certificate_number_c: data.what_is_the_warranty_certificate_number_c ?? '',
				wss_codes_c: data.wss_codes_c ?? '',
				applicator_name: data.applicator_name ?? '',
				stage_of_application_during_complaint_c: data.stage_of_application_during_complaint_c ?? '',
				application_surface_c: data.application_surface_c ?? '',
				area_in_sqft_c: data.area_in_sqft_c?.toString() ?? '',
				observation_c: data.observation_c ?? '',
				courier_company_ref_id: data.courier_company_ref_id ?? '',
			});
			convertedProfile();
			convertedCourier();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<section className="pcms-section">
			<div className="container">
				<PcmsContentCard />
				<Wrapper isError={null} isLoaderVisible={isFetching}>
					<>
						<form>
							<div className="accordion">
								{categories.map((category) => (
									<div key={category} className={`accordion-item ${openItems.includes(category) ? 'active' : ''}`}>
										<div
											tabIndex={0}
											role="button"
											className="accordion-header"
											onClick={() => toggleAccordion(category)}
										>
											<span className="content">{category}</span>
											<span className="icon-bottom-arrow arrow-icon"></span>
										</div>
										<div className="accordion-content">
											<ul className="pcms-registration-wrapper row">{filterFromByCategory(category)}</ul>
										</div>
									</div>
								))}
							</div>
						</form>
						{!state?.id && (
							<SubmitButton
								label="Register Complaint"
								isEditing={false}
								isDisabled={(!formik.isValid || !formik.dirty || isPending) && !state?.id}
								handleSubmitClick={formik.handleSubmit}
								isPending={isPending || isPutSiteImagePending || isPutCourierReceiptPending}
							/>
						)}
						<Modal
							modalId="googleMapModal"
							className="map-popup dialog-sm md-large pt-4"
							modalOpen={showMapModal}
							onModalClose={handleCloseSiteLocationModal}
						>
							<GoogleMapModal handleConfirm={handleLocationSubmit} handleClose={handleCloseSiteLocationModal} />
						</Modal>
						<Modal
							modalId="message"
							className="dialog-sm"
							modalOpen={showConfirmationModal}
							onModalClose={() => {
								setShowConfirmationModal(false);
							}}
						>
							<ConfirmationModal
								onClose={() => {
									setShowConfirmationModal(false);
								}}
								onSubmit={handleModalConfirmation}
								message="Their are no batch details of selected SKU, Please add manually!!"
								buttonLabel={'Ok'}
								hideCancelButton={true}
							/>
						</Modal>
					</>
				</Wrapper>
			</div>
		</section>
	);
};
