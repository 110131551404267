import { RxCollectionCreator } from 'rxdb';

export const FactoryVisitSchema: RxCollectionCreator = {
	schema: {
		title: 'Factory Visit List',
		description: 'Factory Visit Schema',
		version: 0,
		primaryKey: 'id',
		type: 'object',
		properties: {
			id: { type: 'string', maxLength: 250 },
			sfid: { type: 'string' },
			subject: { type: ['string', 'null'] },
			cmdi_code_c: { type: ['string', 'null'] },
			assigned_cmdi_c: { type: ['string', 'null'] },
			membership_number_c: { type: ['string', 'null'] },
			membership_number: { type: ['string', 'null'] },
			activitydatetime: { type: ['string', 'null'] },
			activitydate: { type: ['string', 'null'] },
			app_id: { type: ['string', 'null'] },
			wss_teritorry_name_c: { type: ['string', 'null'] },
			wss_teritorry_code_c: { type: ['string', 'null'] },
			factory_name: { type: ['string', 'null'] },
			factory_location: { type: ['string', 'null'] },
			competition_brand_at_factory: { type: ['boolean', 'null'] },
			brand_name_at_factory: { type: ['string', 'null'] },
			joinery_regular_visit: { type: ['boolean', 'null'] },
			contractor_name: { type: ['string', 'null'] },
			contractor_phone: { type: ['string', 'null'] },
		},
		required: ['id'],
	},
};
