import { ReactElement, useCallback, useMemo, useState } from 'react';
import { contractor360Tabs, getDraftSite, IContractorSites } from '.';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import useStepperStore from '@store/stepperStore';
import { Site } from './site';
import { SiteSummaryDetails } from '@services/hooks/contractor360';
import useSiteLeadStore from '@store/siteLeadStore';
import { Modal } from '@components/common/modal/modal';
import { SixMonthsConfirmationModal } from '@components/contractor360/components';
import { useEndUserProfileStore } from '@store/endUserProfileStore';
import { useEndUserProfileService } from '@services/hooks/endUserProfile';
import { ToastType, useTosterStore } from '@store/toster';
import { overlayRemoveFadeAction } from '@components/common/sort';
import { TabState } from '@components/common/tab-state/tabState';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';
import { Factory } from './factory';
import { SUPERVISOR_ALLOWED_CLASS } from '@config/constant';
import { NoDataFound } from '@components/common/no-data-found/noDataFound';
import { ProductRecommendationModal } from '../productReommendation/productRecommendation';
import { useHandleProductRecommendation } from '@components/contractor360/components/productReommendation';

export const ContractorSites = (props: IContractorSites): ReactElement => {
	const { data, isGetContractorDetailFetching, error, allSites, isSiteSummaryFetching, allFactoryVisits } = props;
	const navigate = useNavigate();
	const { setCurrentStep } = useStepperStore();
	const { setSiteSfid, setKeyBaseValue } = useSiteLeadStore();
	const {
		isConformationNeeded,
		setKeyBaseValue: setEndUserKeyBaseValue,
		openConformationModal: isOpen,
	} = useEndUserProfileStore();
	const { refetch: endUserProfileService } = useEndUserProfileService(data?.id);
	const { setToaster } = useTosterStore();
	const [activeTab, setActiveTab] = useState<number>(0);
	const handleTabClick = useCallback((index: number) => {
		setActiveTab(index);
	}, []);
	const checkTabState = useComponentLevelAccessCheck('tab-state');
	const {
		handleProductRecommendation,
		isProductRecommendationOpen,
		productRecommendationData,
		closeProductRecommendation,
	} = useHandleProductRecommendation({
		membershipno: data?.membershipno,
		contractorclass: data?.contractorclass,
		banking_mtd_kgs: data?.banking_mtd_kgs,
	});

	const handleEndUserProfileVerificationStage = (fn: () => void): void => {
		if (isConformationNeeded && data?.id && endUserProfileService) {
			endUserProfileService()
				.then((response) => {
					if (response.isError) {
						setToaster(true, ToastType.error, 'Please contact admin for enrolled details updates.');
						return;
					}
					setEndUserKeyBaseValue('basicDetailsRevalidate', response?.data?.data?.basic);
					// response?.data?.data?.more For some-time we have to check the basic details revalidation please refer #446608
					setEndUserKeyBaseValue('moreDetailsRevalidate', false);
					setEndUserKeyBaseValue('openConformationModal', true);
				})
				.catch(() => {
					setToaster(true, ToastType.error, 'Please contact admin for enrolled details updates.');
				});
			return;
		}
		fn();
	};

	// Handle Site Data Update and Navigation
	const handleSiteDataUpdate = (): void => {
		setSiteSfid('');
		setKeyBaseValue('addNewSiteLead', false);
		setKeyBaseValue('siteDetails', {
			name: data?.name,
			number: data?.phone,
			sfid: data?.sfid,
			membershipno: data?.membershipno,
		});
		navigate('/add-new-site');
	};

	const handleSiteRedirection = (): void => {
		setCurrentStep(0);
		handleSiteDataUpdate();
	};
	const checkHandleAddNewSite = async (): Promise<void> => {
		handleEndUserProfileVerificationStage(() => {
			handleProductRecommendation(handleSiteRedirection);
		});
	};

	const checkHandleSingleSiteRedirection = (fn: () => void): void => {
		handleEndUserProfileVerificationStage(fn);
	};

	const handleAddNewSite = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault();
		checkHandleAddNewSite().then();
	};

	const handleAddNewFactoryVisit = useCallback(() => {
		navigate('/factory-visit/add-factory-visit', {
			state: {
				membershipNo: data?.membershipno,
			},
		});
	}, [data?.membershipno, navigate]);

	const sortedSites = useMemo((): SiteSummaryDetails[] => {
		if (!Array.isArray(allSites)) return [];

		const sortedByCreatedDate = [...allSites].sort((a, b) => {
			const dateA = a?.createddate ? new Date(a.createddate) : null;
			const dateB = b?.createddate ? new Date(b.createddate) : null;

			if (!dateA) return 1;
			if (!dateB) return -1;

			return dateB.getTime() - dateA.getTime();
		});
		const draftSites = sortedByCreatedDate.filter(getDraftSite);
		const nonDraftSites = sortedByCreatedDate.filter((site) => !getDraftSite(site));

		return [...draftSites, ...nonDraftSites];
	}, [allSites]);

	const closeEndUserModal = useCallback(() => {
		overlayRemoveFadeAction(() => setEndUserKeyBaseValue('openConformationModal', false));
	}, [setEndUserKeyBaseValue]);

	const renderSites = (): ReactElement => (
		<ul className="sites-card-list row">
			{allSites?.length ? (
				sortedSites?.map((site, index) => (
					<Site
						handleEndUserProfileVerificationStage={checkHandleSingleSiteRedirection}
						key={site?.id}
						site={site}
						siteIndex={index}
						membershipno={data?.membershipno}
						contractor_name={data?.name}
					/>
				))
			) : (
				<NoDataFound />
			)}
		</ul>
	);

	const renderFactories = (): ReactElement => (
		<ul className="sites-card-list row">
			{allFactoryVisits?.length ? (
				allFactoryVisits?.map((factory, index) => (
					<Factory
						key={factory?.id}
						factory={factory}
						factoryIndex={index}
						membershipno={factory.membership_number_c}
						contractor_name={factory.contractor_name}
					/>
				))
			) : (
				<NoDataFound />
			)}
		</ul>
	);

	const isTabStateActive = !checkTabState && SUPERVISOR_ALLOWED_CLASS.includes(data?.contractorclass ?? '');
	return (
		<>
			<section className="contractor-sites">
				<div className="container">
					<div className="contractor-sites-inner">
						{isTabStateActive ? (
							<TabState
								dataArray={contractor360Tabs}
								activeTab={activeTab}
								tabClick={handleTabClick}
								addNewSite={handleAddNewSite}
								addNewFactoryVisit={handleAddNewFactoryVisit}
								isGetContractorDetailFetching={isGetContractorDetailFetching}
								btnTitle="Site"
							/>
						) : (
							<div className="sites-title">
								<span className="h3">
									Sites<span className="h5">(6 months)</span>
								</span>
								<button
									type="button"
									id="site-list-btn"
									aria-label="add-new-site-button"
									className="btn btn-secondary btn-small add-new-site-btn"
									value="add-new-site-button"
									onClick={handleAddNewSite}
									disabled={isGetContractorDetailFetching}
								>
									Add New Site{' '}
									<span className="add-new-site-icon">
										<span className="icon-plus"></span>
									</span>
								</button>
							</div>
						)}
						<Wrapper isLoaderVisible={isGetContractorDetailFetching || isSiteSummaryFetching} isError={error}>
							{activeTab === 0 ? renderSites() : renderFactories()}
						</Wrapper>
					</div>
				</div>
			</section>
			{isOpen && (
				<Modal
					modalOpen={isOpen}
					modalId={'closeLeads'}
					onModalClose={closeEndUserModal}
					className="dialog-sm close-site-popup dialog-conform"
				>
					<SixMonthsConfirmationModal
						message={'Kindly review and confirm the highlighted enrollment data.'}
						data={data}
						modalClose={closeEndUserModal}
					/>
				</Modal>
			)}
			{isProductRecommendationOpen.modalOpen && (
				<Modal
					modalTitle={'Product Recommendation'}
					modalOpen={isProductRecommendationOpen.modalOpen}
					modalId={'productRecommendation'}
					onModalClose={closeProductRecommendation}
					className="dialog-xxl dialog-bottom close-site-popup dialog-conform"
				>
					<ProductRecommendationModal
						modalClose={closeProductRecommendation}
						onSubmit={() => {
							closeProductRecommendation();
							handleSiteRedirection();
						}}
						membershipNo={data?.membershipno}
						productRecommendationData={productRecommendationData}
						isUpdate={isProductRecommendationOpen.isUpdate}
					/>
				</Modal>
			)}
		</>
	);
};
