import { SUPERVISOR_ALLOWED_CLASS } from '@config/constant';
import { ToastType, useTosterStore } from '@store/toster';
import {
	IHandleProductRecommendation,
	IUseHandleProductRecommendation,
} from '@components/contractor360/components/productReommendation/index';
import { useComponentLevelAccessCheck } from '../../../../authGuard/hooks/useComponentLevelAccessCheck';
import { useSiderToggleMenu } from '@store/useSiderToggleMenu';
import { useContractorStatusQuery } from '@services/hooks/contractor360/useContractorRecommendedProducts';
import { useCallback, useState } from 'react';
import { overlayRemoveFadeAction } from '@components/common/sort';

export const useHandleProductRecommendation = (
	props: IHandleProductRecommendation
): IUseHandleProductRecommendation => {
	const checkProductRecommendation = useComponentLevelAccessCheck('product-recommendation');
	const { membershipno, banking_mtd_kgs, contractorclass } = props;
	const { setFullPageLoader } = useSiderToggleMenu();
	const { setToaster } = useTosterStore();
	const { mutate: contractorStatus, data: productRecommendationData } = useContractorStatusQuery();
	const [isProductRecommendationOpen, setIsProductRecommendationOpen] = useState<{
		modalOpen: boolean;
		isUpdate: boolean;
	}>({
		modalOpen: false,
		isUpdate: false,
	});

	const handleProductRecommendation = useCallback(
		(fn: () => void): void => {
			if (
				!checkProductRecommendation &&
				membershipno &&
				banking_mtd_kgs &&
				banking_mtd_kgs < 100 &&
				SUPERVISOR_ALLOWED_CLASS.includes(contractorclass ?? '')
			) {
				setFullPageLoader(true);
				contractorStatus(
					{ membershipNo: membershipno },
					{
						onSuccess: (statusData) => {
							setFullPageLoader(false);
							if (!statusData?.is_within_six_months) {
								setIsProductRecommendationOpen((prevState) => ({
									...prevState,
									modalOpen: true,
									isUpdate: statusData.data_already_present,
								}));
								return;
							}
							fn();
						},
						onError: (error) => {
							setFullPageLoader(false);
							setToaster(true, ToastType.error, error.message);
						},
					}
				);
				return;
			}
			fn();
		},
		[
			banking_mtd_kgs,
			checkProductRecommendation,
			contractorStatus,
			contractorclass,
			membershipno,
			setFullPageLoader,
			setToaster,
		]
	);

	const closeProductRecommendation = useCallback((): void => {
		overlayRemoveFadeAction(() => {
			setIsProductRecommendationOpen((prevState) => ({
				...prevState,
				modalOpen: false,
				isUpdate: false,
			}));
		});
	}, [setIsProductRecommendationOpen]);

	return {
		handleProductRecommendation: handleProductRecommendation,
		productRecommendationData: productRecommendationData,
		isProductRecommendationOpen: isProductRecommendationOpen,
		setIsProductRecommendationOpen: setIsProductRecommendationOpen,
		closeProductRecommendation: closeProductRecommendation,
	};
};
