import { create } from 'zustand';
import { IMoredetailIntialValue } from '@components/endUserDetails/components/moreDetailsForm';

interface IFormikValueStore {
	storedFormikValues: IMoredetailIntialValue | null;
	setFormikValuesToStore: (members: IMoredetailIntialValue) => void;
	resetFormikValuesToStore: () => void;
}

const initialState = {
	storedFormikValues: null,
};

export const useStoreFormikFormValue = create<IFormikValueStore>((set) => ({
	...initialState,
	setFormikValuesToStore: (values: IMoredetailIntialValue): void => set({ storedFormikValues: values }),
	resetFormikValuesToStore: (): void => set({ storedFormikValues: null }),
}));
