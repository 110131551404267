import { useAdoptedQuery } from '@services/hooks/endusers/adoptedQuery';
import { useLeadUserQuery } from '@services/hooks/endusers/leadUserQuery';
import { useAllEndUserQuery } from '@services/hooks/endusers/allEndUserQuery';
import { useClubDropDownQuery } from '@services/hooks/endusers/clubListQuery';
import { useDealersQuery } from '@services/hooks/enduser-details/useDealerQuery';
import { useCompetitionBrandsQuery } from '@services/hooks/enduser-details/useCompetitionBrandsQuery';
import { useIMRCodeListQuery } from '@services/hooks/enduser-details/useIMRCodeListQuery';
import { useTownDetailsQuery } from '@services/hooks/enduser-details/useTownDetailsQuery';
import { useAvailableProductsListQuery } from '@services/hooks/site-leads/useAvailableProductsListQuery';
import { useMeetingsListQuery } from '@services/hooks/meetings/useMeetingsListQuery';
import { useMeetingsTypesQuery } from '@services/hooks/meetings/useMeetingTypesQuery';
import { useProfileQuery } from '@services/hooks/profile/useProfileQuery';
import { useSiteSummaryListing } from '@services/hooks/site-summary-listing';
import { useDealerVisitProducts, useDealerVisits } from '@services/hooks/dealerVisit';
import { usePcmsListQuery } from '@services/hooks/pcms-registration/useGetPcmsRegistrationList';
import {
	useDealersListQuery,
	useEndUserCategoryList,
	useEndUserListingQuery,
	useGetOneToOneMeetingsList,
	useWssTerritoryListQuery,
} from '@services/hooks/oneToOneMeetings';
import { useBDEOnLeaveStore } from '@store/bdeOnLeaveStore';
import { QueryObserverResult } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useSiderToggleMenu } from '@store/useSiderToggleMenu';
import { useSupervisorList } from '@services/hooks/supervisor';
import { useFactoryVisitList } from '@services/hooks/factoryVisit';

export const UseSyncData = (): void => {
	useAdoptedQuery();
	useLeadUserQuery();
	useAllEndUserQuery();
	useClubDropDownQuery();
	useDealersQuery();
	useCompetitionBrandsQuery();
	useSiteSummaryListing();
};

interface IRefetchAllData {
	refetchAllData?: (refetchName?: string | string[]) => Promise<void>;
}

type RefetchFunction = () => Promise<QueryObserverResult>;

export const useRefetchAllData = (): IRefetchAllData => {
	const { refetch: refetchAdopted } = useAdoptedQuery();
	const { refetch: refetchLeadUser } = useLeadUserQuery();
	const { refetch: refetchAllEndUser } = useAllEndUserQuery();
	const { refetch: refetchClubDropDown } = useClubDropDownQuery();
	const { refetch: refetchCompetitonBrandsDropDown } = useCompetitionBrandsQuery();
	const { refetch: refetchDealer } = useDealersQuery();
	const { refetch: refetchImrCodeList } = useIMRCodeListQuery();
	const { refetch: refetchTowns } = useTownDetailsQuery();
	const { refetch: refetchProducts } = useAvailableProductsListQuery();
	const { refetch: refetchTypes } = useMeetingsTypesQuery();
	const { refetch: refetchMeetings } = useMeetingsListQuery();
	const { refetch: refetchSiteSummaryListing } = useSiteSummaryListing();
	const { refetch: refetchProfile } = useProfileQuery();
	const { refetch: refetchDealerVisits } = useDealerVisits();
	const { refetch: refetchDealerVisitProducts } = useDealerVisitProducts();
	const { refetch: refetchPcmsList } = usePcmsListQuery();
	const { refetch: refetchOneToOneMeetingList } = useGetOneToOneMeetingsList();
	const { refetch: refetchEndUserList } = useEndUserListingQuery();
	const { refetch: refetchWssTerritoryList } = useWssTerritoryListQuery();
	const { refetch: refetchPrimaryDealersList } = useDealersListQuery();
	const { refetch: refetchEndUserCategoryList } = useEndUserCategoryList();
	const { refetch: refetchSupervisorList } = useSupervisorList();
	const { refetch: refetchFactoryVisitList } = useFactoryVisitList();

	const refetchMap: Record<string, RefetchFunction | undefined> = {
		EndUsers: refetchAdopted,
		Supervisor: refetchSupervisorList,
		AllEndUser: refetchAllEndUser,
		FactoryVisit: refetchFactoryVisitList,
	};
	const { isModalOpen } = useBDEOnLeaveStore();

	const nameWiseRefetchData = async (refetchNames: string | string[]): Promise<void> => {
		const names = Array.isArray(refetchNames) ? refetchNames : [refetchNames];

		const refetchFunctions = names.map((name) => refetchMap[name]).filter((fn): fn is RefetchFunction => !!fn);

		const invalidNames = names.filter((name) => !refetchMap[name]);
		if (invalidNames.length > 0) {
			// eslint-disable-next-line no-console
			console.warn(`No refetch functions found for names: ${invalidNames.join(', ')}`);
		}

		if (refetchFunctions.length > 0) {
			await Promise.all(refetchFunctions.map((fn) => fn()));
		}
	};

	const refetchAllData = async (refetchName: string | string[] = 'all'): Promise<void> => {
		if (isModalOpen) {
			await Promise.all([]);
		}
		if (refetchName === 'all') {
			await Promise.all([
				refetchAdopted?.(),
				refetchLeadUser?.(),
				refetchAllEndUser?.(),
				refetchMeetings?.(),
				refetchSiteSummaryListing?.(),
				refetchDealerVisitProducts?.(),
				refetchOneToOneMeetingList?.(),
				refetchPcmsList?.(),
				refetchTowns?.(),
				refetchDealer?.(),
				refetchProducts?.(),
				refetchCompetitonBrandsDropDown?.(),
				refetchClubDropDown?.(),
				refetchImrCodeList?.(),
				refetchTypes?.(),
				refetchDealerVisits?.(),
				refetchEndUserList?.(),
				refetchWssTerritoryList?.(),
				refetchPrimaryDealersList?.(),
				refetchEndUserCategoryList?.(),
				refetchProfile?.(),
				refetchSupervisorList?.(),
				refetchFactoryVisitList?.(),
			]);
		} else {
			await nameWiseRefetchData(refetchName);
		}
	};

	return { refetchAllData };
};

export const useRefetchList = (refetchName?: string | string[]): void => {
	const { setRefetchName } = useSiderToggleMenu();

	const memoizedRefetchName = useMemo(() => {
		if (!refetchName) return 'all';
		return Array.isArray(refetchName) ? refetchName.join('|') : refetchName;
	}, [refetchName]);

	useEffect(() => {
		setRefetchName(memoizedRefetchName.split('|'));

		return (): void => {
			setRefetchName('all');
		};
	}, [setRefetchName, memoizedRefetchName]);
};
