import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { IFactoryDetailResponse, IFactoryPayload, IFactoryEditPayload } from '@components/factoryDetails';

interface IFactoryVisitData {
	mutate: UseMutateFunction<IFactoryDetailResponse, Error, IFactoryPayload, unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useFactoryVisitMutation = (factoryId: number): IFactoryVisitData => {
	const createFactoryVisit = async (factoryData: IFactoryPayload): Promise<IFactoryDetailResponse> => {
		return await APIService.postData<IFactoryDetailResponse>(`${APICONFIG.FACTORY_VISIT_LIST}`, factoryData);
	};

	const updateFactoryVisit = async (factoryData: IFactoryEditPayload): Promise<IFactoryDetailResponse> => {
		return await APIService.putData<IFactoryDetailResponse>(
			`${APICONFIG.SUPERVISOR_LIST_BY_CONTACT}${factoryId}/`,
			factoryData
		);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: factoryId ? updateFactoryVisit : createFactoryVisit,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
