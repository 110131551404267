import { RxCollectionCreator } from 'rxdb';

export const allEndUserSchema: RxCollectionCreator = {
	schema: {
		title: 'End User schema',
		description: 'create a End user schema',
		version: 6,
		primaryKey: 'sfid',
		type: 'object',
		properties: {
			id: {
				type: 'integer',
			},
			sfid: {
				type: 'string',
				maxLength: 250,
			},
			phone: {
				type: ['string', 'null'],
			},
			is_a_ob: {
				type: 'boolean',
			},
			name: {
				type: 'string',
			},
			status: {
				type: 'string',
			},
			createddate: {
				type: 'string',
				format: 'date-time',
			},
			verification_status: {
				type: ['string', 'null'],
			},
			clubname: {
				type: 'string',
			},
			contractorclass: {
				type: 'string',
			},
			competitionconsumption: {
				type: ['number', 'null'],
			},
			glue_consumption_per_month: {
				type: 'number',
			},
			priority: {
				type: 'integer',
			},
			totalbankingQTD: {
				type: 'number',
			},
			visitstatus: {
				type: ['string', 'null'],
			},
			dateofvisit: {
				type: ['string', 'null'],
				format: 'date-time',
			},
			pwg_banking_qtd: {
				type: 'number',
			},
			membershipno: {
				type: 'string',
			},
			currenttier: {
				type: ['string', 'null'],
			},
			contractorconsumption: {
				type: ['string', 'null'],
			},
			lastactivitydate: {
				type: ['string', 'null'],
				format: 'date-time',
			},
			kyc_id_proof_verification: {
				type: ['boolean', 'null'],
			},
			banking_six_month_kgs: {
				type: 'number',
			},
			total_points_per_kgs: {
				type: 'number',
			},
			banking_points_qtd: {
				type: 'number',
			},
			club_mapping: {
				type: 'string',
			},
			is_zbc: {
				type: 'boolean',
			},
			is_de_growing: {
				type: 'boolean',
			},
			clustercode_c: {
				type: 'string',
			},
			clustername_c: {
				type: 'string',
			},
			lastmodifieddate: { type: ['string', 'null'] },
			personcontactid: { type: ['string', 'null'] },
			is_adopted_by: { type: ['boolean', 'null'] },
			parent_member_id_c: { type: ['string', 'null'] },
			banking_mtd_kgs: { type: ['number', 'null'] },
		},
		required: [
			'id',
			'sfid',
			'is_a_ob',
			'name',
			'status',
			'createddate',
			'clubname',
			'contractorclass',
			'glue_consumption_per_month',
			'priority',
			'totalbankingQTD',
			'pwg_banking_qtd',
			'membershipno',
			'banking_six_month_kgs',
			'total_points_per_kgs',
			'banking_points_qtd',
		],
	},
	migrationStrategies: {
		1: async (oldDoc) => {
			return {
				contractorconsumption: oldDoc.competition_brand_1_used,
				kyc_id_proof_verification: oldDoc.is_kyc_verified_c,
				...oldDoc,
			};
		},
		2: async (oldDoc) => {
			return {
				...oldDoc,
			};
		},
		3: async (oldDoc) => {
			return {
				...oldDoc,
			};
		},
		4: async (oldDoc) => {
			return {
				...oldDoc,
			};
		},
		5: async (oldDoc) => {
			return {
				...oldDoc,
			};
		},
		6: async (oldDoc) => {
			return {
				...oldDoc,
			};
		},
	},
};
