import { ReactElement, useCallback } from 'react';
import { IArchitectDetailProps, TAccordianDetails } from '.';
import { ActionButton, TextInput } from '@components/common';
import { keyDownEvent } from '@helper/utils';
import { useEndUserProfileStore } from '@store/endUserProfileStore';

export const AccordianDetailList = (props: IArchitectDetailProps): ReactElement => {
	const { formik, accordianListIndex, data, accordianName, title, isAddition, isEditing } = props;
	const {
		moreDetailsRevalidate,
		setSubKeyBaseValue: setEndUserKeyBaseValue,
		setSubKeyValue,
		moreDetailFormRevalidateField,
	} = useEndUserProfileStore();

	const handleAddRemoveRevalidateField = (childIndex: number | undefined, remove = true): void => {
		const setValue = (name: boolean[], number: boolean[]): void => {
			setSubKeyValue('moreDetailFormRevalidateField', 'architect_name', name);
			setSubKeyValue('moreDetailFormRevalidateField', 'architect_number', number);
		};
		if (!moreDetailsRevalidate) return;
		const architect_name: boolean[] = [...moreDetailFormRevalidateField.architect_name];
		const architect_number: boolean[] = [...moreDetailFormRevalidateField.architect_number];
		if (childIndex && remove) {
			architect_name.splice(childIndex, 1);
			architect_number.splice(childIndex, 1);
			setValue(architect_name, architect_number);
			return;
		}
		architect_name.push(true);
		architect_number.push(true);
		setValue(architect_name, architect_number);
	};

	const handleAddArchitectDetails = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			e.preventDefault();
			formik.setFieldValue(accordianName, [
				...formik.values[accordianName],
				{
					id: formik.values[accordianName].length,
					name: '',
					number: '',
					isActive: true,
				},
			]);
			handleAddRemoveRevalidateField(0, false);
		},
		[accordianName, formik]
	);
	const handleRemoveArchitectDetails = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, childIndex: number | undefined) => {
			e.preventDefault();
			if (formik.values[accordianName].length > 1) {
				const updatedArchitectDetails = formik.values[accordianName].filter(
					(_: TAccordianDetails, i: number) => i !== childIndex
				);
				formik.setFieldValue(accordianName, updatedArchitectDetails);
				handleAddRemoveRevalidateField(childIndex);
			}
		},
		[accordianName, formik]
	);

	const handleToggle = useCallback(() => {
		const updatedArchitectDetails = formik.values[accordianName].map((item: TAccordianDetails, index: number) => {
			if (index === accordianListIndex) {
				return {
					...item,
					isActive: !item.isActive,
				};
			}
			return item;
		});
		formik.setFieldValue(accordianName, updatedArchitectDetails);
	}, [accordianName, accordianListIndex, formik]);

	return (
		<li className="d-col d-col-2">
			<div className={`details-box-wrapper ${data?.isActive || moreDetailsRevalidate ? 'active' : ''}`}>
				<span className="details-title">{title}</span>
				<span
					aria-hidden={true}
					title="Toggle icon"
					aria-label="Toggle menu"
					className="icon-plus-circle plus-icon"
					onClick={handleToggle}
				></span>
				<div className="input-details-wrapper">
					<div className="input-details">
						<TextInput
							type="text"
							placeholder="Name"
							id={`${accordianName}_${accordianListIndex}`}
							name={`${accordianName}[${accordianListIndex}].name`}
							onBlur={formik.handleBlur}
							value={formik.values[accordianName][accordianListIndex]?.name}
							setFieldValue={formik.setFieldValue}
							error={
								formik.touched?.[accordianName]?.[accordianListIndex]?.name && formik.errors[accordianName]
									? formik.errors[accordianName][accordianListIndex]?.name
									: null
							}
							disabled={isEditing}
							onChange={() => {
								setEndUserKeyBaseValue('moreDetailFormRevalidateField', 'architect_name', accordianListIndex, true);
							}}
							revalidate={moreDetailsRevalidate && !moreDetailFormRevalidateField.architect_name[accordianListIndex]}
							revalidateError={'Please revalidate architect and interior designer name'}
						/>
					</div>

					<div className="input-details">
						<TextInput
							type="text"
							placeholder="Number"
							id={`${accordianName}_${accordianListIndex}`}
							name={`${accordianName}[${accordianListIndex}].number`}
							onBlur={formik.handleBlur}
							value={formik.values[accordianName][accordianListIndex]?.number}
							setFieldValue={formik.setFieldValue}
							onKeyDown={keyDownEvent}
							disabled={isEditing}
							maxLength={10}
							error={
								formik.touched?.[accordianName]?.[accordianListIndex]?.number && formik.errors[accordianName]
									? formik.errors[accordianName][accordianListIndex]?.number
									: null
							}
							onChange={() => {
								setEndUserKeyBaseValue('moreDetailFormRevalidateField', 'architect_number', accordianListIndex, true);
							}}
							revalidate={moreDetailsRevalidate && !moreDetailFormRevalidateField.architect_number[accordianListIndex]}
							revalidateError={'Please revalidate architect and interior designer number'}
						/>
					</div>
				</div>
				<div className="add-remove-wrapper">
					{formik.values[accordianName].length > 1 && accordianListIndex !== 0 && (
						<ActionButton
							label="Remove"
							handlerFn={handleRemoveArchitectDetails}
							className="remove-btn"
							childIndex={accordianListIndex}
							disabled={isEditing}
							action="remove"
						/>
					)}
					{isAddition &&
						accordianListIndex === formik.values[accordianName].length - 1 &&
						formik.values[accordianName].length < 3 && (
							<ActionButton label="Add More" handlerFn={handleAddArchitectDetails} disabled={isEditing} action="add" />
						)}
				</div>
			</div>
		</li>
	);
};
