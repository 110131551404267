import { APIService } from '@services/api/common-services';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { APICONFIG } from '@config/api.config';
import { RecommendedProductData } from '@components/contractor360/components/contractorSites';

interface IDealerVisitData {
	mutate: UseMutateFunction<RecommendedProductData[], Error, RecommendedProductData[], unknown>;
	error: Error | null;
	isError: boolean;
	isPending: boolean;
}

export const useAddUpdateRecommendedProducts = (
	isUpdate: boolean,
	membershipNo: string | null | undefined
): IDealerVisitData => {
	const createRecommendedProduct = async (visitData: RecommendedProductData[]): Promise<RecommendedProductData[]> => {
		return await APIService.postData<RecommendedProductData[]>(`${APICONFIG.ADD_PRODUCT_RECOMMENDATION}`, visitData);
	};
	const updateRecommendedProduct = async (visitData: RecommendedProductData[]): Promise<RecommendedProductData[]> => {
		return await APIService.putData<RecommendedProductData[]>(
			`${APICONFIG.ADD_PRODUCT_RECOMMENDATION}${membershipNo}/`,
			visitData
		);
	};

	const { mutate, error, isError, isPending } = useMutation({
		mutationFn: isUpdate ? updateRecommendedProduct : createRecommendedProduct,
	});

	return {
		mutate,
		error,
		isError,
		isPending,
	};
};
