export * from './competitionConversion';
export * from './filter';
export * from './siteSummaryListingFilterStore';
export * from './siteAllDetailsStore';
export * from './endUserProfileStore';
export * from './useCommonModalPopUpStore';
export * from './supervisorListStore';
export * from './setFormikFormValueStore';

export const initialFilterObject = {
	dateOfVisit: { flagged: false, startDate: null, endDate: null },
	kycStatus: { flagged: false, kycStatus: '' },
	visitStatus: { flagged: false, visitStatus: '' },
	customToggle: { flagged: false, data: [{ value: 'OB' }, { value: 'NCC' }], toggleData: [] },
	checkSecondary: [{ flagged: false, name: '', data: [{ value: 'OB' }, { value: 'NCC' }], secondaryData: [] }],
	customSelect: { flagged: false, customData: [] },
	singleDropDown: {
		flagged: false,
		name: 'Source',
		data: [
			{ value: 'Dealer', id: 1 },
			{ value: 'FCC', id: 2 },
			{ value: 'BDE', id: 3 },
			{ value: 'IMR', id: 4 },
		],
		dropDownData: '',
	},
	largeContractor: {
		flagged: false,
		name: '',
		dropDownData: '',
		value: '',
	},
};
