import { DropdownOptions } from '@components/common/formControl/dropdown';
import { AccordianDetail } from '@components/endUserDetails/components/moreDetailsForm';
import { ISiteLeadVisits } from '@components/SiteLeadOverview';

export * from './siteSummaryDetails';

export interface ISiteSummaryDetail {
	dealer_code: string;
	contract_name: string;
	site_state_c: string | null;
	competition_products_at_site_c: string | null;
	type_of_contract: string;
	app_id_c: string;
	assigned_cmdi_c: string;
	site_status_c: string | null;
	id: number;
	name: string;
	grade_glue_used_at_the_site: string;
	brand_of_glue_used_at_site: string | null;
	createddate: string | Date;
	owner_mobile_no: string;
	glue_potential: string;
	status_c: string | null;
	sfid: string;
	visits: ISiteLeadVisits[];
	owner_name: string;
	type_of_record_c: string;
	site_type: string;
	glue_used_at_the_site: string;
	visit_recorded: boolean;
	sitenumber_c: string;
	sitecode_c: string | null;
	end_user_c: string;
	dealer_name: string;
	lastactivitydate: string | Date;
	architect_name: string;
	market: string;
	current_stage_of_site_c: string;
	contract_phone: string;
	architect_mobile_no: string;
	site_location_c: string;
	reason_of_competition: string;
	membership_no: string;
	roff_substrate_c: string;
	epoxy_adhesive_used_c: string;
}

export interface IInitialFormikSiteDetails {
	name: string;
	site_location_c: string;
	grade_glue_used_at_the_site: string;
	brand_of_glue_used_at_site: string;
	contract_name: string;
	contract_phone: string;
	type_of_contract: string;
	reason_of_competition: string;
	glue_used_at_the_site: string;
	site_type: string;
	dealer_code: string;
	dealer_name: string;
	glue_potential: string;
	architect_name: string;
	architect_mobile_no: string;
	owner_name: string;
	owner_mobile_no: string;
	owner_details: AccordianDetail[];
	architect_details: AccordianDetail[];
	substratesCombinations: DropdownOptions[];
	veneer: string;
}

export const initialFormikSiteDetails: IInitialFormikSiteDetails = {
	name: '',
	site_location_c: '',
	grade_glue_used_at_the_site: '',
	brand_of_glue_used_at_site: '',
	contract_name: '',
	contract_phone: '',
	type_of_contract: '',
	reason_of_competition: '',
	glue_used_at_the_site: '',
	site_type: '',
	dealer_code: '',
	dealer_name: '',
	glue_potential: '',
	architect_name: '',
	architect_mobile_no: '',
	owner_name: '',
	owner_mobile_no: '',
	owner_details: [],
	architect_details: [],
	substratesCombinations: [],
	veneer: '',
};

export const siteLeadTabs = [
	{
		key: 1,
		name: 'Site Visits',
	},
	{
		key: 2,
		name: 'Factory Visit',
	},
];
