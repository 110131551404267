import { ReactElement } from 'react';
import { TabStateProps } from '.';

export const TabState = ({
	dataArray,
	activeTab,
	tabClick,
	addNewSite,
	addNewFactoryVisit,
	isGetContractorDetailFetching,
	btnTitle,
}: TabStateProps): ReactElement => {
	return (
		<div className="tab-state-wrapper">
			<div className="tab-search-wrapper">
				<div className="tab-wrapper">
					<div className="tab-main">
						<div className="tab-inner">
							<ul className="tab">
								{dataArray.length !== 0 &&
									dataArray?.map((tab, index: number) => {
										return (
											<li
												key={tab.name + `${index + 1}`}
												onClick={() => tabClick(index)}
												className={activeTab === index ? 'active' : ''}
												role="none"
											>
												<div aria-label={tab.name} className="tablinks">
													{tab?.tabIcon && (
														<span className="new-site-icon icon">
															<span className={tab.tabIcon}></span>
														</span>
													)}
													<div className="sites-tab-title">
														<span className={`h3 ${activeTab === index ? 'full' : 'half'}`}>
															{tab.name}
															{tab?.duration && <span className="h5">{tab.duration}</span>}
														</span>
													</div>
												</div>
											</li>
										);
									})}
							</ul>
							<div className={`sliding-indicator tab-${activeTab}`}></div>
						</div>
					</div>
				</div>
			</div>

			<div className="sites-title-new">
				{activeTab === 0 ? (
					<button
						type="button"
						id="site-list-btn"
						aria-label="add-new-site-button"
						className="btn btn-secondary btn-small add-new-site-btn"
						value="add-new-site-button"
						onClick={addNewSite}
						disabled={isGetContractorDetailFetching}
					>
						Add New {btnTitle}
						<span className="add-new-site-icon">
							<span className="icon-plus"></span>
						</span>
					</button>
				) : (
					<button
						type="button"
						className="btn btn-secondary btn-small add-new-site-btn max-button"
						onClick={addNewFactoryVisit}
					>
						Add New Factory Visit{''}
						<span className="add-new-site-icon">
							<span className="icon-plus"></span>
						</span>
					</button>
				)}
			</div>
		</div>
	);
};
