import { BDEPermissions } from './BDEPermissions';
import { IMRPermission } from './IMRPermissions';
import { KAEPermissions } from './KAEPermissions';
import { TSIPermission } from './TSIPermissions';

export type Role = 'BDE' | 'IMR' | 'TSI' | 'KAE';

export interface IPermissionModel {
	name: string;
	route: string;
	components: string[];
}

export const RBAC_MODEL: Record<Role, IPermissionModel[]> = {
	BDE: BDEPermissions,
	IMR: IMRPermission,
	TSI: TSIPermission,
	KAE: KAEPermissions,
};
