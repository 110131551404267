import { AdoptedContractorDetails, ISiteVisits, SiteSummaryDetails } from '@services/hooks/contractor360';
import { SiteLeadOverStateProps } from '@components/SiteLeadOverview';
import { IFactoryVisitDetail } from '@components/factoryList';

export interface IContractorSites {
	data: AdoptedContractorDetails | SiteLeadOverStateProps | undefined;
	isGetContractorDetailFetching?: boolean;
	error: Error | null;
	allSites: SiteSummaryDetails[] | undefined;
	isSiteSummaryFetching?: boolean;
	allFactoryVisits?: IFactoryVisitDetail[];
	productionRecommendation?: string | number | null;
}

export interface ISite {
	site: SiteSummaryDetails;
	siteIndex: number;
	membershipno?: string | null;
	handleEndUserProfileVerificationStage?: (fn: () => void) => void;
	contractor_name?: string | null;
}

export const getSortedVisits = (visits: ISiteVisits[] = []): ISiteVisits[] => {
	return [...visits].sort((a, b) => {
		const dateA = a?.activitydatetime ? new Date(a.activitydatetime) : null;
		const dateB = b?.activitydatetime ? new Date(b.activitydatetime) : null;
		if (!dateA) return 1;
		if (!dateB) return -1;
		return dateB.getTime() - dateA.getTime();
	});
};

export const getDraftSite = (site: SiteSummaryDetails): boolean =>
	site?.current_stage_of_site_c?.toLowerCase() === 'draft' ||
	(site?.current_stage_of_site_c?.toLowerCase() === 'site visited' &&
		site?.visits?.length > 0 &&
		getSortedVisits(site.visits)?.[0].site_visit_status?.toLowerCase() === 'draft');

export const contractor360Tabs = [
	{
		key: 1,
		name: 'Sites',
		tabIcon: 'icon-tool-filled',
		duration: '6 months',
	},
	{
		key: 2,
		name: 'Factory',
		tabIcon: 'icon-factory',
		duration: '6 months',
	},
];

export interface IFactory {
	factory: IFactoryVisitDetail;
	factoryIndex: number;
	membershipno?: string | null;
	handleEndUserProfileVerificationStage?: (fn: () => void) => void;
	contractor_name?: string | null;
}

export interface RecommendedProductData {
	product: string; // Product ID
	product_name?: string; // Display name
	demoed_in_last_six_months: boolean;
	purchased_in_last_six_months: boolean;
	membership_no: string | number | null | undefined; // Membership number, can be null
	id?: number;
}

export interface ContractorStatus {
	data_already_present: boolean;
	is_within_six_months: boolean;
}
