import { formatDateDDMMYY } from '@helper/utils';
import { useFilterStore, initialFilterObject } from '@store/index';
import { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { GetMemberListData } from '@services/hooks/endusers/useMembersListRxDb';
import { RightTabCount } from '../count/RightTabCount';

export const FilterTags = (): ReactElement => {
	const { filterObject, updateFilterObject } = useFilterStore((state) => state);
	const { pathname } = useLocation();
	const {
		dateOfVisit,
		kycStatus,
		visitStatus,
		customToggle,
		checkSecondary,
		customSelect,
		singleDropDown,
		largeContractor,
	} = filterObject;
	const { startDate, endDate } = dateOfVisit;
	const kycStatusValue = kycStatus.kycStatus;
	const visitStatusValue = visitStatus.visitStatus;
	const customData = customToggle.toggleData;
	const secondaryData = checkSecondary;
	const customSelectData = customSelect;
	const dropDownData = singleDropDown.dropDownData;
	const getMemberListType = (): string => {
		switch (pathname) {
			case '/end-users':
				return 'adopted_contractor';
			case '/end-users/new-leads':
				return 'new_leads';
			default:
				return 'all';
		}
	};

	const { totalCount } = GetMemberListData(getMemberListType());

	const handleFilterResetHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
		e.preventDefault();
		updateFilterObject({
			...initialFilterObject,
			dateOfVisit: { flagged: filterObject.dateOfVisit.flagged, startDate: null, endDate: null },
			customToggle: {
				flagged: filterObject.customToggle.flagged,
				data: filterObject.customToggle.data,
				toggleData: [],
			},
			visitStatus: { flagged: filterObject.visitStatus.flagged, visitStatus: '' },
			checkSecondary: filterObject.checkSecondary.map((item) => ({
				flagged: item.flagged,
				name: item.name,
				data: item.data,
				secondaryData: [],
			})),
			customSelect: { flagged: filterObject.customSelect.flagged, customData: [] },
			kycStatus: { flagged: filterObject.kycStatus.flagged, kycStatus: '' },
			singleDropDown: {
				flagged: filterObject.singleDropDown.flagged,
				name: filterObject.singleDropDown.name,
				data: filterObject.singleDropDown.data,
				dropDownData: '',
			},
			largeContractor: {
				flagged: filterObject.largeContractor.flagged,
				dropDownData: '',
				name: filterObject.largeContractor.name,
				value: '',
			},
		});
	};
	return (
		<div className="filter-tags">
			<ul className="tag-list">
				{customSelectData.customData.length > 0 && (
					<li>
						{customSelectData.customData.map((item) => item.value).join(', ')}
						<NavLink
							to="#"
							aria-label="disable tag"
							onClick={() =>
								updateFilterObject({
									...filterObject,
									customSelect: { ...filterObject.customSelect, customData: [] },
								})
							}
						>
							<span className="icon-cross"></span>
						</NavLink>
					</li>
				)}
				{startDate && endDate && (
					<li>
						{`${formatDateDDMMYY(startDate)} to ${formatDateDDMMYY(endDate)}`}
						<NavLink
							to="#"
							aria-label="disable tag"
							onClick={() =>
								updateFilterObject({
									...filterObject,
									dateOfVisit: { ...filterObject.dateOfVisit, startDate: null, endDate: null },
								})
							}
						>
							<span className="icon-cross"></span>
						</NavLink>
					</li>
				)}
				{kycStatusValue && (
					<li>
						{kycStatusValue}
						<NavLink
							to="#"
							aria-label="disable tag"
							onClick={() =>
								updateFilterObject({
									...filterObject,
									kycStatus: { ...filterObject.kycStatus, kycStatus: '' },
								})
							}
						>
							<span className="icon-cross"></span>
						</NavLink>
					</li>
				)}
				{visitStatusValue && (
					<li>
						{visitStatusValue}
						<NavLink
							to="#"
							aria-label="disable tag"
							onClick={() =>
								updateFilterObject({
									...filterObject,
									visitStatus: { ...filterObject.visitStatus, visitStatus: '' },
								})
							}
						>
							<span className="icon-cross"></span>
						</NavLink>
					</li>
				)}
				{secondaryData.length > 0 &&
					secondaryData.map((item, index) => (
						<>
							{item.secondaryData.length > 0 && (
								<li key={item.name}>
									{item.secondaryData.join(', ')}
									<NavLink
										to="#"
										aria-label="disable tag"
										onClick={() =>
											updateFilterObject({
												...filterObject,
												checkSecondary: filterObject.checkSecondary.map((checkItem, idx) => {
													if (idx === index) {
														return {
															...checkItem,
															secondaryData: [],
														};
													}
													return checkItem;
												}),
											})
										}
									>
										<span className="icon-cross"></span>
									</NavLink>
								</li>
							)}
						</>
					))}

				{dropDownData.length > 0 && (
					<li>
						{dropDownData}

						<NavLink
							to="#"
							aria-label="disable tag"
							onClick={() => {
								updateFilterObject({
									...filterObject,
									singleDropDown: { ...filterObject.singleDropDown, dropDownData: '' },
								});
							}}
						>
							<span className="icon-cross"></span>
						</NavLink>
					</li>
				)}

				{customData.length > 0 && (
					<>
						{customData.map((item) => (
							<li key={item}>
								{item}

								<NavLink
									to="#"
									aria-label="disable tag"
									onClick={() => {
										const updatedCustomData = customData.filter((dataItem) => dataItem !== item);
										updateFilterObject({
											...filterObject,
											customToggle: { ...filterObject.customToggle, toggleData: updatedCustomData },
											largeContractor: { ...filterObject.largeContractor, dropDownData: '', value: '' },
										});
									}}
								>
									<span className="icon-cross"></span>
								</NavLink>
							</li>
						))}
					</>
				)}
				{customData?.includes('Sub Contractor') && largeContractor.dropDownData && (
					<li>
						{largeContractor.dropDownData}
						<NavLink
							to="#"
							aria-label="disable tag"
							onClick={() => {
								updateFilterObject({
									...filterObject,
									largeContractor: { ...filterObject.largeContractor, dropDownData: '', value: '' },
								});
							}}
						>
							<span className="icon-cross"></span>
						</NavLink>
					</li>
				)}
				<a href={pathname} aria-label="Clear All" className="clear-all-btn" onClick={handleFilterResetHandler}>
					Clear All
				</a>
			</ul>
			<RightTabCount count={totalCount} />
		</div>
	);
};
