import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';

type QuarterSummaryData = {
	total_meeting: number;
	site_visits: number;
	total_user_enrollment: number;
	zero_banking_users: number;
	de_growing_users: number;
	factory_visits: number;
};

export const useQuarterSummaryQuery = (): IQueryResponse<QuarterSummaryData> => {
	const fetchQuarterSummaryData = async (): Promise<QuarterSummaryData> => {
		return await APIService.getData<QuarterSummaryData>(APICONFIG.QUARTER_SUMMARY_API);
	};

	const { data, error, isLoading, isPending, isFetching } = useQuery({
		queryKey: ['quarterSummary'],
		queryFn: fetchQuarterSummaryData,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
	};
};
