import { useRxData } from 'rxdb-hooks';
import { MangoQuery, RxCollection, RxDocument } from 'rxdb/dist/types/types';
import { useCallback } from 'react';
import { useRxCountQuery } from '@hooks/useCount';
import { ISupervisorBankingHistoryDetail } from '@components/contractor360/components/supervisorBankingHistory';
import { Value } from '@components/common/date-picker';

export type ISupervisorBankingHistoryReturn = {
	result: RxDocument<ISupervisorBankingHistoryDetail>[];
	isFetching: boolean;
	fetchMore: () => void;
	totalCount: number;
};

export interface ISupervisorBankingHistoryFilterProps {
	searchValue?: string;
	createddate?: Value;
}

export const useSupervisorBankingHistoryRxDb = (
	props: ISupervisorBankingHistoryFilterProps
): ISupervisorBankingHistoryReturn => {
	const { searchValue = '', createddate } = props;

	const queryConstructor = useCallback(
		(collection: RxCollection<ISupervisorBankingHistoryDetail>) => {
			const querySelector: MangoQuery<ISupervisorBankingHistoryDetail> = {
				selector: {
					$or: [
						{
							supervisor_name: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							supervisor_phone: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							barcode_no: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							item_code: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
						{
							item_name: { $regex: `.*${searchValue}.*`, $options: 'i' },
						},
					],
				},
				sort: [{ billing_time: 'desc' }],
			};

			if (querySelector.selector && createddate) {
				const [startDate, endDate] = createddate;
				if (startDate && endDate) {
					querySelector.selector.billing_time = {
						$gte: startDate.toISOString(),
						$lte: endDate.toISOString(),
					};
				}
			}

			return collection.find(querySelector);
		},
		[createddate, searchValue]
	);
	const totalCount = useRxCountQuery<ISupervisorBankingHistoryDetail>('supervisor_banking_history', queryConstructor);

	const { result, isFetching, fetchMore } = useRxData<ISupervisorBankingHistoryDetail>(
		'supervisor_banking_history',
		queryConstructor,
		{
			pageSize: 20,
			pagination: 'Infinite',
		}
	);

	return {
		result,
		isFetching,
		fetchMore,
		totalCount,
	};
};
