import { PurchaseButton } from '@components/common';
import { ReactElement } from 'react';
import { ISchemeDiscussion } from '.';
import { FCPStatus } from '@components/common/fcp-status/fcpStatus';

export const SchemeDiscussion = (props: ISchemeDiscussion): ReactElement => {
	const { formik, slabData, fpcDetails } = props;

	const handleChange = (value: string): void => {
		formik.setFieldValue('slab', value);
	};

	return (
		<div className="scheme-discussion-wrapper">
			<h2 className="list-title">
				<span className="title-wrapper">
					<span className="title-number">2</span>
					<span className="title-name">Scheme Discussion</span>
				</span>
			</h2>

			<div className="scheme-discussion-about">
				<span className="scheme-title">Slab Scheme Update</span>
				<p className="scheme-about">
					{slabData?.data?.total_banked ?? '0'} KGs banked. Slab {slabData?.data?.slab ?? 'NA'}
					{slabData?.data?.slab && slabData?.data?.slab !== 'NA' ? 'KGs' : ''}. {slabData?.data?.remaining_kgs ?? '100'}{' '}
					KGs to go for {slabData?.data?.next_slab ?? '100-200'} KG slab.
				</p>

				<span className="purchas-btn-wrapper">
					<PurchaseButton
						label="Yes"
						icon="icon-thumbs-up"
						handleClick={() => handleChange('True')}
						isActive={formik.values.slab === 'True'}
					/>

					<PurchaseButton
						label="No"
						icon="icon-thumbs-down"
						handleClick={() => handleChange('False')}
						isActive={formik.values.slab === 'False'}
					/>
				</span>

				{fpcDetails?.data && (
					<FCPStatus
						track={fpcDetails?.data?.track}
						pwg_ytd={fpcDetails?.data?.pwg_ytd}
						balance={fpcDetails?.data?.balance}
					/>
				)}
			</div>
		</div>
	);
};
