export type ISidebarConfig = {
	route: string;
	ariaLabel: string | { default: string; TSI?: string; IMR?: string };
	icon: string;
	key: string;
};

export const SidebarConfig: ISidebarConfig[] = [
	{
		key: 'end-users',
		route: '/end-users',
		ariaLabel: 'Members',
		icon: 'icon-users',
	},
	{
		key: 'supervisor',
		route: '/supervisor',
		ariaLabel: 'Supervisor',
		icon: 'icon-users',
	},
	{
		key: 'factory-visit',
		route: '/factory-visit',
		ariaLabel: 'Factory Visit',
		icon: 'icon-factory',
	},
	{
		key: 'meetings',
		route: '/meetings',
		ariaLabel: { default: 'Meetings', TSI: 'One to Many' },
		icon: 'icon-coffee',
	},
	{
		key: 'site-summary',
		route: '/site-summary',
		ariaLabel: 'Site Leads',
		icon: 'icon-tool',
	},
	{
		key: 'dealer-visit-summary',
		route: '/dealer-visit-summary',
		ariaLabel: 'Dealer Visit',
		icon: 'icon-package',
	},
	{
		key: 'one-to-one-meeting-summary',
		route: '/one-to-one-meeting-summary',
		ariaLabel: 'One to One Meetings',
		icon: 'icon-star-calander',
	},
	{
		key: 'gallery',
		route: '/gallery',
		ariaLabel: 'Gallery',
		icon: 'icon-gallery',
	},
	{
		key: 'field-marketing-content',
		route: '/field-marketing-content',
		ariaLabel: 'Field Marketing Content',
		icon: 'icon-gallery',
	},
	{
		key: 'structured-questionnaires',
		route: '/structured-questionnaires',
		ariaLabel: 'Structured Questionnaires',
		icon: 'icon-structured-questionnaire',
	},
	{
		key: 'pcms',
		route: '/pcms',
		ariaLabel: 'PCMS',
		icon: 'icon-tv',
	},
	{
		key: 'help-and-support',
		route: '/help-and-support',
		ariaLabel: 'Help & Support',
		icon: 'icon-help-circle',
	},
];
